//
//
//
//
//
//
//
//


import Chartist from 'chartist'

export default {
  // Tab name
  name: 'scatter-plot',
  
  // Objects stored on the component instance
  data() {
    return {
        container: null
    }
  },

  // Objects passed from parent (App)
  props: [],

  methods: {
    // Entry point
    init() {  
        this.container = document.getElementById( 'viewer' );
        
        var times = function(n) {
            return Array.apply(null, new Array(n));
        };

        var data = times(50).map(Math.random).reduce(
            function(data, rnd, index) {
                data.labels.push(index);
                data.series.forEach(function(series) {
                    series.push(Math.random() * 100)
                });

                return data;
            }, 
            {
                labels: [],
                series: times(3).map(function() { return new Array() }) 
            }
        );

        var options = {
            showLine: false,
            height: '400px',
            width: '400px',
            axisX: {
                labelInterpolationFnc: function(value, index) {
                    return index % 5 === 0 ? value : null;
                }
            }
        };

        var responsiveOptions = [
            [
                'screen and (min-width: 640px)', {
                    axisX: {
                        labelInterpolationFnc: function(value, index) {
                            return index % 5 === 0 ? value : null;
                        }
                    }
                }
            ]
        ];

        new Chartist.Line('.ct-chart', data, options, responsiveOptions);
        }
    },

    // Function called when component is initially mounted
    mounted() {
        this.init()
    },

    // Dispose
    beforeDestroy: function () {

    }
}

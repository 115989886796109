//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

export default {
  // Tab name
  name: 'webgl-basic-material',
  
  // Objects stored on the component instance
  data() {
    return {
      scene: null,
      camera: null,
      orbitControls: null,
      frameId: null,
      container: null,
      mesh: null,
      wireframe: null,
      points: null
    }
  },

  // Objects passed from parent (App)
  props: ['renderer', 'stats'],

  methods: {
    // Entry point
    init: function() {  
      this.container = document.getElementById( 'viewer' );
      this.container.style.height = window.innerHeight - 50 + "px";

      // Orthographic Camera
      this.camera = new THREE.OrthographicCamera( 
        80 * window.innerWidth / window.innerHeight / - 2, // left - Camera frustum left plane
        80 * window.innerWidth / window.innerHeight / 2,  // right - Camera frustum right plane
        80 / 2, // top - Camera frustum top plane
        80 / - 2,  // bottom - Camera frustum bottom plane
        1, // near - Camera frustum near plane
        2000 // far - Camera frustum far plane
      )
      this.camera.position.set( 0, 0, 500 );

      // Scene
      this.scene = new THREE.Scene();
      this.scene.add(this.camera);

      // Light
      let pointLight = new THREE.PointLight( 0xffffff );
      pointLight.position.set(1,1,2);
      this.camera.add(pointLight);

      // Build axis and grid
      this.buildSceneHelpers();

      // Geometry
      let geometry = new THREE.TorusKnotBufferGeometry( 12, 3, 120, 20 );
      //var geometry = new THREE.IcosahedronBufferGeometry( 25, 4 );
      
      // Mesh
      let material = new THREE.MeshPhongMaterial( { color: 0xbb00ff, specular: 0x111111, shininess: 100, side: THREE.DoubleSide, flatShading: false } );
      this.mesh = new THREE.Mesh(geometry, material);
      this.mesh.name = 'mesh';
      
      // Wireframe
      let wireframeMaterial = new THREE.MeshBasicMaterial( { color: 0x2d2d2d, wireframe: true } );
      this.wireframe = new THREE.Mesh(geometry, wireframeMaterial);
      this.wireframe.name = 'wireframe';
      this.wireframe.visible = false;

      // Points
      let pointMaterial = new THREE.PointsMaterial( { size: 3.5, color: 0xff0000 } )
      let clone = this.mesh.clone();
      let positions = clone.geometry.attributes.position;
      var bufferGeom = new THREE.BufferGeometry();
      bufferGeom.setAttribute('position', positions);
      this.points = new THREE.Points(bufferGeom, pointMaterial);
      this.points.name = 'points';
      
      this.scene.add(this.points);
      this.scene.add(this.mesh);
      this.scene.add(this.wireframe);

      // Controls
      this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

      // Render
      this.$emit('setRendererSize', [this.container.innerWidth, this.container.innerHeight]);
      this.container.appendChild(this.renderer.domElement);
      
      // Events
      window.addEventListener('resize', this.onWindowResize, false);
      // Ensure window size is up-to-date
      window.dispatchEvent(new Event('resize'));
    },

    // Main render function
    render: function() {
      this.stats.update();

      // Rotate
      this.mesh.rotation.x = this.mesh.rotation.y += 0.015;
      this.wireframe.rotation.x = this.wireframe.rotation.y += 0.015;
      this.points.rotation.x = this.points.rotation.y += 0.015;

      this.renderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
    },

    toggleVisibility: function(objectName){
      this.scene.traverse (function (object) {
          if (object.name === objectName) { 
              if(object.visible === false) { object.visible = true; }
              else { object.visible = false; } 
          }
      });
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function() {
      // Grid
      const size = 100;
      const step = 5;
      let gridHelper = new THREE.BufferGeometry();
      let gridMaterial = new THREE.LineBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.1 });
      
      const vertices = [];

      for (var i = -size; i <= size; i += step) {
        vertices.push(-size, 0, i);
        vertices.push(size, 0, i);
        vertices.push(i, 0, -size);
        vertices.push(i, 0, size);
      }

      gridHelper.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

      let gridLines = new THREE.LineSegments(gridHelper, gridMaterial, THREE.LineSegments);
      gridLines.name = "grid";
      this.scene.add(gridLines);
      
      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function(obj) {
      while(obj.children.length > 0){ 
        this.clearObj(obj.children[0])
        obj.remove(obj.children[0]);
      }
      if(obj.geometry) obj.geometry.dispose()

      if(obj.material){ 
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach(prop => {
          if(!obj.material[prop])
            return         
          if(typeof obj.material[prop].dispose === 'function')                                  
            obj.material[prop].dispose()                                                        
        })
        obj.material.dispose()
      }
    },   

    onWindowResize: function() {
      // Updating orthographic camera
      this.camera.left = 80 * window.innerWidth / window.innerHeight / - 2;
      this.camera.right = 80 * window.innerWidth / window.innerHeight / 2;
      this.camera.top = 80 / 2;
      this.camera.bottom = 80 / - 2;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize( window.innerWidth, window.innerHeight );
    }

    /*
    onMouseMove: function(event) {
        event.preventDefault();
        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
    }
    */
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
    this.render()
  },
  
  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener('resize', this.onWindowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  }

}

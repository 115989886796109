//
//
//
//
//
//

import ConstraintProcessing from './Markdown/Constraint-Processing.md';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'constraint-processing',
  components: { ConstraintProcessing }
}

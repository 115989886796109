//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

export default {
  // Tab name
  name: 'webgl-glsl-simple',
  
  // Objects stored on the component instance
  data() {
    return {
        colorPicker: Object,
        mesh: Object,
        geometry: Object,
        material: Object,
        triangleCount: Number,
        colors: Array,

        scene: null,
        camera: null,
        orbitControls: null,
        frameId: null,
        container: null
    }
  },

  // Objects passed from parent (App)
  props: ['renderer', 'stats'],

  methods: {
    // Entry point
    init: function() {  
        this.container = document.getElementById( 'viewer' );
        this.container.style.height = window.innerHeight - 50 + "px";

        // Orthographic Camera
        this.camera = new THREE.OrthographicCamera( 
        80 * window.innerWidth / window.innerHeight / - 2, // left - Camera frustum left plane
        80 * window.innerWidth / window.innerHeight / 2,  // right - Camera frustum right plane
        80 / 2, // top - Camera frustum top plane
        80 / - 2,  // bottom - Camera frustum bottom plane
        1, // near - Camera frustum near plane
        2000 // far - Camera frustum far plane
        );
        this.camera.position.set( 0, 0, 100 );

        this.colorPicker = {
            colorOne: '#ff00ff',
            colorTwo: '#00ffff',
            colorThree: '#ffff00'
        };

        // Build initial buffer geometry
        this.geometry = new THREE.TorusBufferGeometry( 15, 3, 60, 15 );
        
        // Update vertex colors
        this.updateColors();
        
        // Create mesh name for reference
        this.mesh.name = 'GLSLSample';
        
        // Scene
        this.scene = new THREE.Scene();
        this.scene.add(this.camera);
        this.scene.add(this.mesh);

        // Build axis and grid
        //this.buildSceneHelpers();
        
        // Controls
        this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

        // Render
        this.$emit('setRendererSize', [this.container.offsetWidth, this.container.offsetHeight]);
        this.container.appendChild(this.renderer.domElement);
        
        // Events
        window.addEventListener('resize', this.onWindowResize, false);
        // Ensure window size is up-to-date
        window.dispatchEvent(new Event('resize'));
    },

    // A helper function for updating vertex colors
    updateColors: function() {
        this.triangleCount = this.geometry.attributes.position.count;
        this.colors = [];

        // UI color options
        let c1 = this.hexToRgb(this.colorPicker.colorOne);
        let c2 = this.hexToRgb(this.colorPicker.colorTwo);
        let c3 = this.hexToRgb(this.colorPicker.colorThree);
        
        this.colors = [];
        
        for ( let i = 0; i < this.triangleCount; i ++ ) {
            if( i % 2 === 0 ) {
                this.colors.push( c1.r );
                this.colors.push( c1.g );
                this.colors.push( c1.b );
                this.colors.push( 255 );
            }
            else if( i < ( this.triangleCount / 4 ) ) {
                this.colors.push( c2.r );
                this.colors.push( c2.g );
                this.colors.push( c2.b );
                this.colors.push( 255 );
            }
            else {
                this.colors.push( c3.r );
                this.colors.push( c3.g );
                this.colors.push( c3.b );
                this.colors.push( 255 );
            }
        }

        let colorAttribute = new THREE.Uint8BufferAttribute( this.colors, 4 );
        colorAttribute.normalized = true; // this will map the buffer values to 0.0f - +1.0f in the shader
        this.geometry.setAttribute( 'color', colorAttribute );

        this.material = new THREE.RawShaderMaterial( {
            uniforms: {
                time: { value: 1.0 }
            },
            vertexShader: document.getElementById( 'vertexShader' ).textContent,
            fragmentShader: document.getElementById( 'fragmentShader' ).textContent,
            side: THREE.DoubleSide,
            transparent: true
        } );

        this.mesh = new THREE.Mesh( this.geometry, this.material );
    },

    // A helper function for converting hex to rgb
    hexToRgb: function(hex) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }, 

    // Main render function
    render: function() {
      // Monitored code goes here
      //this.stats.begin();
      this.stats.update();
      this.mesh.rotation.x  = this.mesh.rotation.z += 0.02;
      this.renderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
      //this.stats.end();
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function() {
      // Grid
      const size = 100;
      const step = 5;
      let gridHelper = new THREE.BufferGeometry();
      let gridMaterial = new THREE.LineBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.1 });
      
      const vertices = [];

      for (var i = -size; i <= size; i += step) {
        vertices.push(-size, 0, i);
        vertices.push(size, 0, i);
        vertices.push(i, 0, -size);
        vertices.push(i, 0, size);
      }

      gridHelper.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

      let gridLines = new THREE.LineSegments(gridHelper, gridMaterial, THREE.LineSegments);
      gridLines.name = "grid";
      this.scene.add(gridLines);
      
      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function(obj) {
      while(obj.children.length > 0){ 
        this.clearObj(obj.children[0])
        obj.remove(obj.children[0]);
      }
      if(obj.geometry) obj.geometry.dispose()

      if(obj.material){ 
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach(prop => {
          if(!obj.material[prop])
            return         
          if(typeof obj.material[prop].dispose === 'function')                                  
            obj.material[prop].dispose()                                                        
        })
        obj.material.dispose()
      }
    },   

    onWindowResize: function() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize( window.innerWidth, window.innerHeight );

        // Updating an orthographic camera
        this.camera.left = 80 * window.innerWidth / window.innerHeight / - 2;
        this.camera.right = 80 * window.innerWidth / window.innerHeight / 2;
        this.camera.top = 80 / 2;
        this.camera.bottom = 80 / - 2;
        this.camera.updateProjectionMatrix();
    }
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
    this.render()
  },
  
  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener('resize', this.onWindowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  }

}

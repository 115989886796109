//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  // Tab name
  name: 'geodesic-dome',

  // Slider
components: {
  VueSlider
},

  // Objects stored on the component instance
  data() {
    return {
      dome: null,
      diameter: 100,
      tessellation: 4,
      scene: null,
      camera: null,
      orbitControls: null,
      frameId: null,
      container: null
    }
  },

  // Objects passed from parent (App)
  props: ['renderer', 'stats'],

  watch: { 
      diameter: {
          immediate: true, 
          handler (val, oldVal) {
              this.update();
          }
      },
      tessellation: {
          immediate: true, 
          handler (val, oldVal) {
              this.update();
          }
      }
  },

  methods: {
    // Entry point
    init: function() {  
      this.container = document.getElementById( 'viewer' );
      this.container.style.height = window.innerHeight - 50 + "px";
          
      // Camera
      this.camera = new THREE.PerspectiveCamera(
        45, // fov - Camera frustum vertical field of view
        this.container.offsetWidth / this.container.offsetHeight, // aspect - Camera frustum aspect ratio.
        1, // near - Camera frustum near plane.
        10000 // far - Camera frustum far plane.
      )
      this.camera.position.set( -150, 0, -100);

      // Orthographic Camera
      /*
      this.camera = new THREE.OrthographicCamera( 
        80 * window.innerWidth / window.innerHeight / - 2, // left - Camera frustum left plane
        80 * window.innerWidth / window.innerHeight / 2,  // right - Camera frustum right plane
        80 / 2, // top - Camera frustum top plane
        80 / - 2,  // bottom - Camera frustum bottom plane
        1, // near - Camera frustum near plane
        2000 // far - Camera frustum far plane
      )
      */

      // Geometry
      this.dome = this.generateGeodesicDome(this.diameter, 4);
      this.dome[0].rotation.y = -Math.PI / 2;
      this.dome[0].rotation.x = -Math.PI / 2;
      this.dome[1].rotation.y = -Math.PI / 2;
      this.dome[1].rotation.x = -Math.PI / 2;

      // Light
      const ambientLight = new THREE.AmbientLight( 0x404040 ); // soft white light
      const pointLight = new THREE.PointLight( 0xffffff );
      pointLight.position.set(1,1,2);
      this.camera.add(pointLight);

      // Scene
      this.scene = new THREE.Scene();
      this.scene.add(this.camera, ambientLight);
      this.scene.add(this.dome[0], this.dome[1]);

      // Build axis and grid
      //this.buildSceneHelpers();
      
      // Controls
      this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

      // Render
      this.$emit('setRendererSize', [this.container.offsetWidth, this.container.offsetHeight]);
      this.container.appendChild(this.renderer.domElement);
      
      // Events
      window.addEventListener('resize', this.onWindowResize, false);
      // Ensure window size is up-to-date
      window.dispatchEvent(new Event('resize'));
    },

    update: function() {
      if (this.scene != null) {
          // Remove previous
          const mesh = this.scene.getObjectByName("mesh");
          this.scene.remove(mesh);
          const wireframe = this.scene.getObjectByName("wireframe");
          this.scene.remove(wireframe);
          // Add new
          this.dome = this.generateGeodesicDome(this.diameter, this.tessellation);
          this.dome[0].rotation.y = -Math.PI / 2;
          this.dome[0].rotation.x = -Math.PI / 2;
          this.dome[1].rotation.y = -Math.PI / 2;
          this.dome[1].rotation.x = -Math.PI / 2;
          this.scene.add(this.dome[0], this.dome[1]);
      }
    },

    // Main render function
    render: function() {
      // Monitored code goes here
      //this.stats.begin();
      this.dome[0].rotation.x += 0.01;
      this.dome[1].rotation.x += 0.01;
      this.dome[0].rotation.y += 0.01;
      this.dome[1].rotation.y += 0.01;
      this.stats.update();
      this.renderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
      //this.stats.end();
    },


    generateGeodesicDome: function(diameter, tessellationLevel) {
          const phi = (1 + Math.sqrt(5)) / 2;
          const a = Math.sqrt((3 + 4 * phi) / 5) / 2;
          const b = Math.sqrt(phi / Math.sqrt(5));
          const c = Math.sqrt(3 / 4 - a ** 2);
          const d = Math.sqrt(3 / 4 - (b - a) ** 2);
          
          // Icosahedron in cylindrical coordinates
          const vertices = [
              [0, 0, (c + d / 2)],
              [b, 2 * 0 * Math.PI / 5, d / 2],
              [b, 2 * 1 * Math.PI / 5, d / 2],
              [b, 2 * 2 * Math.PI / 5, d / 2],
              [b, 2 * 3 * Math.PI / 5, d / 2],
              [b, 2 * 4 * Math.PI / 5, d / 2],
              [b, (2 * 0 + 1) * Math.PI / 5, -d / 2],
              [b, (2 * 1 + 1) * Math.PI / 5, -d / 2],
              [b, (2 * 2 + 1) * Math.PI / 5, -d / 2],
              [b, (2 * 3 + 1) * Math.PI / 5, -d / 2],
              [b, (2 * 4 + 1) * Math.PI / 5, -d / 2],
              [0, 0, -(c + d / 2)],
          ];

          // Convert cylindrical to Cartesian coordinates
          const cartesianVertices = vertices.map(v => [
              v[0] * Math.cos(v[1]),
              v[0] * Math.sin(v[1]),
              v[2]
          ]);

          // Normalize to sphere radius
          const radius = diameter / 2;
          let normalizedVertices = cartesianVertices.map(v => {
              const len = Math.sqrt(v[0] ** 2 + v[1] ** 2 + v[2] ** 2);
              return [v[0] / len * radius, v[1] / len * radius, v[2] / len * radius];
          });

          // Rotate vertices to align x-axis as up
          normalizedVertices = normalizedVertices.map(v => {
              const [x, y, z] = v;
              return [z, y, -x];
          });

          // Faces of the icosahedron
          const faces = [
              [0, 1, 2],
              [0, 2, 3],
              [0, 3, 4],
              [0, 4, 5],
              [0, 5, 1],
              [1, 6, 2],
              [2, 6, 7],
              [2, 7, 3],
              [3, 7, 8],
              [3, 8, 4],
              [4, 8, 9],
              [4, 9, 5],
              [5, 9, 10],
              [5, 10, 1],
              [1, 10, 6],
              [6, 11, 7],
              [7, 11, 8],
              [8, 11, 9],
              [9, 11, 10],
              [10, 11, 6]
          ];

          // Tessellate
          for (let i = 0; i < tessellationLevel; i++) {
              this.tessellate(normalizedVertices, faces, radius);
          }

          // Remove lower hemisphere
          const filteredVertices = [];
          const vertexMap = new Map();
          let vertexIndex = 0;

          for (let i = 0; i < normalizedVertices.length; i++) {
              if (normalizedVertices[i][0] >= 0) { // X component
                  filteredVertices.push(normalizedVertices[i][0], normalizedVertices[i][1], normalizedVertices[i][2]);
                  vertexMap.set(i, vertexIndex++);
              }
          }

          const filteredFaces = [];

          for (let i = 0; i < faces.length; i++) {
              const a = faces[i][0];
              const b = faces[i][1];
              const c = faces[i][2];

              if (vertexMap.has(a) && vertexMap.has(b) && vertexMap.has(c)) {
                  filteredFaces.push(vertexMap.get(a), vertexMap.get(b), vertexMap.get(c));
              }
          }

          // Create the buffer geometry
          const domeGeometry = new THREE.BufferGeometry();
          domeGeometry.setAttribute('position', new THREE.Float32BufferAttribute(filteredVertices, 3));
          domeGeometry.setIndex(filteredFaces);
          domeGeometry.computeVertexNormals();

          // Create a material
          const material = new THREE.MeshPhongMaterial( {
              color: 0xff00ff,
              polygonOffset: true,
              polygonOffsetFactor: 1, // positive value pushes polygon further away
              polygonOffsetUnits: 1,
              side: THREE.DoubleSide,
          } );

          const geo = new THREE.EdgesGeometry( domeGeometry ); // or WireframeGeometry
          const mat = new THREE.LineBasicMaterial( { color: 0x000000 } );
          const mesh = new THREE.Mesh(domeGeometry, material);
          mesh.name = "mesh";
          const wireframe = new THREE.LineSegments( geo, mat );
          wireframe.name = "wireframe";

          // Create the dome mesh
          return [mesh, wireframe];
      },

      tessellate: function(vertices, faces, radius) {
          const newFaces = [];
          const midPointCache = {};

          function getMidPoint(a, b) {
              const key = `${Math.min(a, b)}_${Math.max(a, b)}`;
              if (!midPointCache[key]) {
                  const mid = [
                      (vertices[a][0] + vertices[b][0]) / 2,
                      (vertices[a][1] + vertices[b][1]) / 2,
                      (vertices[a][2] + vertices[b][2]) / 2
                  ];
                  const len = Math.sqrt(mid[0] ** 2 + mid[1] ** 2 + mid[2] ** 2);
                  midPointCache[key] = vertices.length;
                  vertices.push([mid[0] / len * radius, mid[1] / len * radius, mid[2] / len * radius]);
              }
              return midPointCache[key];
          }

          for (let i = 0; i < faces.length; i++) {
              const [a, b, c] = faces[i];
              const ab = getMidPoint(a, b);
              const bc = getMidPoint(b, c);
              const ca = getMidPoint(c, a);

              newFaces.push([a, ab, ca], [b, bc, ab], [c, ca, bc], [ab, bc, ca]);
          }

          faces.length = 0;
          faces.push(...newFaces);
      },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function() {
      // Grid
      const size = 100;
      const step = 5;
      let gridHelper = new THREE.BufferGeometry();
      let gridMaterial = new THREE.LineBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.1 });
      
      const vertices = [];

      for (var i = -size; i <= size; i += step) {
        vertices.push(-size, 0, i);
        vertices.push(size, 0, i);
        vertices.push(i, 0, -size);
        vertices.push(i, 0, size);
      }

      gridHelper.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

      let gridLines = new THREE.LineSegments(gridHelper, gridMaterial, THREE.LineSegments);
      gridLines.name = "grid";
      this.scene.add(gridLines);
      
      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function(obj) {
      while(obj.children.length > 0){ 
        this.clearObj(obj.children[0])
        obj.remove(obj.children[0]);
      }
      if(obj.geometry) obj.geometry.dispose()

      if(obj.material){ 
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach(prop => {
          if(!obj.material[prop])
            return         
          if(typeof obj.material[prop].dispose === 'function')                                  
            obj.material[prop].dispose()                                                        
        })
        obj.material.dispose()
      }
    },   

    onWindowResize: function() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize( window.innerWidth, window.innerHeight );

        // Updating an orthographic camera
        /*
        this.camera.left = 80 * window.innerWidth / window.innerHeight / - 2;
        this.camera.right = 80 * window.innerWidth / window.innerHeight / 2;
        this.camera.top = 80 / 2;
        this.camera.bottom = 80 / - 2;
        this.camera.updateProjectionMatrix();
        */
    }
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
    this.render()
  },
  
  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener('resize', this.onWindowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  }

}

//
//
//
//
//
//

import GoProgrammingReference from './Markdown/Go-Programming-Reference.md';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'go-programming-reference',
  components: { GoProgrammingReference }
}

//
//
//
//
//
//

import EthereumVirtualMachine from './Markdown/Ethereum-Virtual-Machine.md';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'ethereum-virtual-machine',
  components: { EthereumVirtualMachine }
}

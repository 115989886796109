//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  // Tab name
  name: 'roGAN',
  
  components: {
      VueSlider
},

  // Objects stored on the component instance
  data() {
    return {
        container: null,
        colour: '#2ca02c', // Initial brush colour
        canvas: null,
        context: null,
        latestPoint: null,
        drawing: false, // Drawing state
        strokeWidth: 25 // Brush size
    }
  },

  // Objects passed from parent (App)
  props: [],

  methods: {
    // Entry point
    init() {  
        // Set up our drawing context
        this.canvas = document.getElementById("canvas");
        this.context = this.canvas.getContext("2d");

        this.context.fillStyle = "#bcbd22";
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

        // Drawing functions
        const continueStroke = newPoint => {
            this.context.beginPath();
            this.context.moveTo(this.latestPoint[0], this.latestPoint[1]);
            this.context.strokeStyle = this.colour;
            this.context.lineWidth = this.strokeWidth;
            this.context.lineCap = "round";
            this.context.lineJoin = "round";
            this.context.lineTo(newPoint[0], newPoint[1]);
            this.context.stroke();
            this.latestPoint = newPoint;
        };

        // Event helpers
        let startStroke = point => {
            this.drawing = true;
            this.latestPoint = point;
        };

        const BUTTON = 0b01;
        const mouseButtonIsDown = buttons => (BUTTON & buttons) === BUTTON;

        // Event handlers
        let mouseMove = evt => {
            if (!this.drawing) {
                return;
            }

            let pos = this.getMousePos(this.canvas, evt)
            continueStroke([pos.x, pos.y]);
        };

        let mouseDown = evt => {
            if (this.drawing) {
                return;
            }
            evt.preventDefault();
            this.canvas.addEventListener("mousemove", mouseMove, false);

            let pos = this.getMousePos(this.canvas, evt)
            startStroke([pos.x, pos.y]);
        };

        let mouseEnter = evt => {
            if (!mouseButtonIsDown(evt.buttons) || this.drawing) {
                return;
            }
            mouseDown(evt);
        };

        let endStroke = evt => {
            if (!this.drawing) {
                return;
            }
            this.drawing = false;
            evt.currentTarget.removeEventListener("mousemove", mouseMove, false);
        };

        let updateContext = evt => {
            this.canvas = document.getElementById("canvas");
            this.context = this.canvas.getContext("2d");
        };

        // Register event handlers
        this.canvas.addEventListener("mousedown", mouseDown, false);
        this.canvas.addEventListener("mouseup", endStroke, false);
        this.canvas.addEventListener("mouseout", endStroke, false);
        this.canvas.addEventListener("mouseenter", mouseEnter, false);
        this.canvas.addEventListener("resize", updateContext, false);

        // Touch handlers (mobile/tablet)
        this.canvas.addEventListener("touchstart", this.touchHandler, true);
        this.canvas.addEventListener("touchmove", this.touchHandler, true);
        this.canvas.addEventListener("touchend", this.touchHandler, true);
        this.canvas.addEventListener("touchcancel", this.touchHandler, true);

        window.addEventListener('resize', this.onWindowResize, false);
        
    },

    changeColour(hexString) {
        this.colour = '#' + hexString
    },

    reset() {
        var canvas = document.getElementById("canvas");
        var ctx = canvas.getContext("2d");
        ctx.fillStyle = "#bcbd22";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    },

    runModel() {
        console.log("calling ML model...");
        document.body.style.cursor = 'wait';

        // Convert INPUT div to base64 encoded image
        var canvas = document.getElementById("canvas");
        var img = canvas.toDataURL("image/png");
        img = img.replace(/^data:image\/(png|jpg);base64,/, "");
        
        // Send post request to 
        var xhr = new XMLHttpRequest();
        var url = "https://s694nzjxcg.execute-api.us-east-1.amazonaws.com/default/roGAN";
        xhr.open("POST", url, true);
        xhr.timeout = 60000; // 2 min
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                var json = JSON.parse(xhr.responseText);
                //console.log("request complete:");
                var output = JSON.parse(json.body);
                //console.log(output.result)
                var image = document.getElementById("result")
                image.src = 'data:image/png;base64,' + output.result;
                document.body.style.cursor = 'default';
            }
            else {
                //console.log("request polling...");
                //console.log(xhr.readyState);
                //console.log(xhr.status);
            }
        };
        
        var data = JSON.stringify( { "body": img } );
        xhr.send(data);
    },

    downloadResults() {
        console.log("downloading results...");
    
        // Export canvas input as png
        var canvas = document.getElementById("canvas");
        var img = canvas.toDataURL("image/png");
        var link = document.createElement("a");
        document.body.appendChild(link); // for Firefox

        // Download
        link.setAttribute("href", img);
        link.setAttribute("download", "roGAN-input.png");
        link.click();

        // Export resulting image as png
        var img = document.getElementById("result");
        var link = document.createElement("a");
        document.body.appendChild(link); // for Firefox
        
        // Download
        link.setAttribute("href", img.src);
        link.setAttribute("download", "roGAN-output.png");
        link.click();
    },

    touchHandler(event)
    {
        var touches = event.changedTouches,
            first = touches[0],
            type = "";
        switch(event.type)
        {
            case "touchstart": type = "mousedown"; break;
            case "touchmove":  type = "mousemove"; break;        
            case "touchend":   type = "mouseup";   break;
            default:           return;
        }

        // initMouseEvent(type, canBubble, cancelable, view, clickCount, 
        //                screenX, screenY, clientX, clientY, ctrlKey, 
        //                altKey, shiftKey, metaKey, button, relatedTarget);

        var simulatedEvent = document.createEvent("MouseEvent");
        simulatedEvent.initMouseEvent(type, true, true, window, 1, 
                                    first.screenX, first.screenY, 
                                    first.clientX, first.clientY, false, 
                                    false, false, false, 0/*left*/, null);

        first.target.dispatchEvent(simulatedEvent);
        event.preventDefault();
    },

    // Scale mouse position relatively to window size
    getMousePos(canvas, evt) {
        var rect = canvas.getBoundingClientRect();
        return {
            x: (evt.clientX - rect.left) / (rect.right - rect.left) * canvas.width,
            y: (evt.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height
        };
    }

  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
  },
  
  // Dispose
  beforeDestroy: function () {

  }

}

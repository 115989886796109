//
//
//
//
//
//

import LeetCode from './Markdown/LeetCode.md';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'leetcode',
  components: { LeetCode }
}

//
//
//
//
//
//

import VSCodeFileRecovery from './Markdown/VS-Code-File-Recovery.md';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'vs-code-file-recovery',
  components: { VSCodeFileRecovery }
}

//
//
//
//
//
//

import OpenCVCpp from './Markdown/OpenCV-Cpp-VisualStudio-Setup.md';
import hljs from 'highlight.js/lib/highlight';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'openCV-cpp-setup',
  components: { OpenCVCpp }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import detectEthereumProvider from "@metamask/detect-provider";

export default {
  // Tab name
  name: "metamask-auth",

  data() {
    return {
      ethereum: undefined,
      accounts: undefined,
      account: undefined,
      walletPermissions: undefined,
    };
  },

  methods: {
    init(provider) {
      this.ethereum = provider;
    },
    async checkEthProvider() {
      const provider = await detectEthereumProvider();
      if (provider) {
        // From now on, this should always be true:
        // provider === window.ethereum
        // Initialize your app
        this.init(provider);
      } else {
        window.alert("Please install MetaMask!");
      }
    },
    async auth() {
      this.accounts = await this.ethereum.request({
        method: "eth_requestAccounts",
      });
      this.account = this.accounts[0];
      this.walletPermissions = await this.ethereum.request({
        method: "wallet_getPermissions",
      });
    },
  },

  mounted() {
    this.checkEthProvider();
  },
};

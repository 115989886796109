//
//
//
//
//
//

import Ethernaut from './Markdown/Ethernaut-Solutions.md';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'ethernaut-solutions',
  components: { Ethernaut }
}

import GeodesicDome from './GeodesicDome';
import CanvasTextureLabels from './CanvasTextureLabels';
import SpriteLabels from './SpriteLabels';
import MeshSelection from './MeshSelection';
import EdgeShader from './EdgeShader';
import PolkadotJSRPC from './PolkadotJSRPC';
import YouTubeMesh from './YouTubeMesh';
import VisualizeGCode from './VisualizeGCode';
import Facade from './Facade';
import TopoLoader from './TopoLoader';
import HexGrid from './HexGrid';
import MetaMaskAuth from './MetaMaskAuth';
import GifGenerator from './GifGenerator';
import FlockingParticles from './FlockingParticles';
//import VisualProgramming from './VisualProgramming'
import DragAndDrop from './DragAndDrop';
import AuthenticationSample from './AuthenticationSample';
import BoundingBoxTool from './BoundingBoxTool';
import RoGAN from './RoGAN';
import TensorFlowObjectDetection from './TensorFlowObjectDetection';
import Formula1RaceStats from './Formula1RaceStats';
import SVGLoader from './SVGLoader';
import ActivationFunctions from './ActivationFunctions';
import ScatterPlot from './ScatterPlot';
import LinePlot from './LinePlot';
//import TensorFlowJS from './TensorFlowJS'
import ParticlesTemplate from './ParticlesTemplate';
import GLSLSample from './GLSLSample';
import OBJLoader from './OBJLoader';
import PointSample from './PointSample';
import MaterialSample from './MaterialSample';
import ColorSample from './ColorSample';
import SliderSample from './SliderSample';
import SceneTemplate from './SceneTemplate';

export default {
    GeodesicDome,
    CanvasTextureLabels,
    SpriteLabels,
    MeshSelection,
    EdgeShader,
    PolkadotJSRPC,
    YouTubeMesh,
    VisualizeGCode,
    Facade,
    TopoLoader,
    HexGrid,
    MetaMaskAuth,
    GifGenerator,
    FlockingParticles,
    //VisualProgramming,
    DragAndDrop,
    AuthenticationSample,
    BoundingBoxTool,
    RoGAN,
    TensorFlowObjectDetection,
    Formula1RaceStats,
    SVGLoader,
    ActivationFunctions,
    ScatterPlot,
    LinePlot,
    //TensorFlowJS,
    ParticlesTemplate,
    GLSLSample,
    OBJLoader, 
    PointSample, 
    MaterialSample, 
    ColorSample, 
    SliderSample, 
    SceneTemplate
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GifWriter } from "./../utility/js/omggif";

export default {
  // Tab name
  name: "gif-generator",

  data() {
    return {
      scene: new THREE.Scene(),
      camera: (this.camera = new THREE.OrthographicCamera(
        (80 * window.innerWidth) / window.innerHeight / -2, // left - Camera frustum left plane
        (80 * window.innerWidth) / window.innerHeight / 2, // right - Camera frustum right plane
        80 / 2, // top - Camera frustum top plane
        80 / -2, // bottom - Camera frustum bottom plane
        1, // near - Camera frustum near plane
        2000 // far - Camera frustum far plane
      )),
      orbitControls: null,
      frameId: null,
      container: null,
      mesh: null,
      wireframe: null,
      points: null,

      canvas: null,
      progress: null,
      button: null,
    };
  },

  props: ["renderer", "stats"],

  methods: {
    // Entry point
    init() {
      this.container = document.getElementById("viewer");
      this.container.style.height = window.innerHeight - 50 + "px";

      // Orthographic Camera
      this.camera.position.set(0, 0, 500);

      // Scene
      this.scene.add(this.camera);

      // Light
      let pointLight = new THREE.PointLight(0xffffff);
      pointLight.position.set(1, 1, 2);
      this.camera.add(pointLight);

      // Build axis and grid
      //this.buildSceneHelpers();

      // Geometry
      let geometry = new THREE.TorusKnotBufferGeometry(12, 0.75, 120, 10, 6, 5);

      // Mesh
      let material = new THREE.MeshPhongMaterial({
        color: 0xFFC81E,
        specular: 0x111111,
        shininess: 100,
        side: THREE.DoubleSide,
        flatShading: false,
      });
      this.mesh = new THREE.Mesh(geometry, material);
      this.mesh.name = "mesh";

      this.scene.add(this.mesh);

      // Controls
      this.orbitControls = new OrbitControls(
        this.camera,
        this.renderer.domElement
      );

      // Render
      this.$emit("setRendererSize", [
        this.container.innerWidth,
        this.container.innerHeight,
      ]);
      this.container.appendChild(this.renderer.domElement);

      // Events
      window.addEventListener("resize", this.onWindowResize, false);
      // Ensure window size is up-to-date
      window.dispatchEvent(new Event("resize"));

      this.canvas = this.renderer.domElement;
      this.button = document.getElementById("gifDownload");
      this.progress = document.getElementById("progress");

      // GIF Download
      this.button.addEventListener("click", this.downloadGif);

      requestAnimationFrame(this.animation);
    },

    // Main render function
    render() {
      this.stats.update();

      // Rotate
      this.mesh.rotation.x = this.mesh.rotation.y = this.progress.value * Math.PI * 2;

      this.renderer.render(this.scene, this.camera);
      //this.frameId = requestAnimationFrame(this.render);
    },

    animation(time) {
      if (this.progress.style.display === "none") {
        // Only render when not generating
        this.progress.value = ( time / 5000 ) % 1;
        this.render();
      }

      requestAnimationFrame(this.animation);
    },

    // Download a gif
    async downloadGif() {
      this.button.style.display = "none";
      this.progress.style.display = "";

      // Generate
      const buffer = await this.generateGIF(this.canvas, this.render, 4, 30);
      this.button.style.display = "";
      this.progress.style.display = "none";

      // Download
      const blob = new Blob([buffer], { type: "image/gif" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "animation.gif";
      link.dispatchEvent(new MouseEvent("click"));
    },

    // Generate a gif
    generateGIF(element, renderFunction, duration = 1, fps = 30) {
      const frames = duration * fps;
      const canvas = document.createElement("canvas");
      canvas.width = element.width;
      canvas.height = element.height;

      const context = canvas.getContext("2d");

      const buffer = new Uint8Array(canvas.width * canvas.height * frames * 5);
      const pixels = new Uint8Array(canvas.width * canvas.height);
      const writer = new GifWriter(buffer, canvas.width, canvas.height, {
        loop: 0,
      });

      let current = 0;

      return new Promise(async function addFrame(resolve) {
        renderFunction(this);

        context.drawImage(element, 0, 0);

        const data = context.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        ).data;

        const palette = [];

        for (var j = 0, k = 0, jl = data.length; j < jl; j += 4, k++) {
          const r = Math.floor(data[j + 0] * 0.1) * 10;
          const g = Math.floor(data[j + 1] * 0.1) * 10;
          const b = Math.floor(data[j + 2] * 0.1) * 10;
          const color = (r << 16) | (g << 8) | (b << 0);

          const index = palette.indexOf(color);

          if (index === -1) {
            pixels[k] = palette.length;
            palette.push(color);
          } else {
            pixels[k] = index;
          }
        }

        // Force palette to be power of 2

        let powof2 = 1;
        while (powof2 < palette.length) powof2 <<= 1;
        palette.length = powof2;

        const delay = 100 / fps; // Delay in hundredths of a sec (100 = 1s)
        const options = { palette: new Uint32Array(palette), delay: delay };
        writer.addFrame(0, 0, canvas.width, canvas.height, pixels, options);

        current++;

        progress.value = current / frames;

        if (current < frames) {
          await setTimeout(addFrame, 0, resolve);
        } else {
          resolve(buffer.subarray(0, writer.end()));
        }
      });
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function () {
      // Grid
      const size = 100;
      const step = 5;
      let gridHelper = new THREE.BufferGeometry();
      let gridMaterial = new THREE.LineBasicMaterial({
        color: 0x000000,
        transparent: true,
        opacity: 0.1,
      });

      const vertices = [];

      for (var i = -size; i <= size; i += step) {
        vertices.push(-size, 0, i);
        vertices.push(size, 0, i);
        vertices.push(i, 0, -size);
        vertices.push(i, 0, size);
      }

      gridHelper.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(vertices, 3)
      );

      let gridLines = new THREE.LineSegments(
        gridHelper,
        gridMaterial,
        THREE.LineSegments
      );
      gridLines.name = "grid";
      this.scene.add(gridLines);

      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(0.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj(obj) {
      while (obj.children.length > 0) {
        this.clearObj(obj.children[0]);
        obj.remove(obj.children[0]);
      }
      if (obj.geometry) obj.geometry.dispose();

      if (obj.material) {
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach((prop) => {
          if (!obj.material[prop]) return;
          if (typeof obj.material[prop].dispose === "function")
            obj.material[prop].dispose();
        });
        obj.material.dispose();
      }
    },

    // Events
    onWindowResize: function () {
      // Updating orthographic camera
      this.camera.left = (80 * window.innerWidth) / window.innerHeight / -2;
      this.camera.right = (80 * window.innerWidth) / window.innerHeight / 2;
      this.camera.top = 80 / 2;
      this.camera.bottom = 80 / -2;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
  },

  mounted() {
    this.init();
  },

  // Dispose
  beforeDestroy: function () {},
};

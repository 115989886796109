//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import router from './../../router'
import sketches from './../sketches'
import notes from './../notes'

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'

export default {
  // Tab name
  name: 'webgl-obj-loader',
  
  // Objects stored on the component instance
  data() {
    return {
      cube: null,
      scene: null,
      camera: null,
      orbitControls: null,
      frameId: null,
      container: null,
      shadedGeometry: new THREE.Group(),
      wireframeGeometry: new THREE.Group(),
      pointGeometry: new THREE.Group(),

      searchKeys: null,
      searchResults: null
    }
  },

  // Objects passed from parent (App)
  props: ['renderer', 'stats'],

  methods: {
    // Entry point
    init: function() {  
        // Build a list of all active sketches and notes

        let sketchList = Object.keys(sketches);
        let noteList = Object.keys(notes);

        // Build routes
        this.searchKeys = [];

        for(let i = 0; i < sketchList.length; i++) {
          this.searchKeys.push('../sketches/' + sketches[sketchList[i]].name);
        }

        for(let i = 0; i < noteList.length; i++) {
          this.searchKeys.push('../notes/' + notes[noteList[i]].name);
        }

        this.searchResults = document.getElementById('search-results')

        this.container = document.getElementById( 'viewer' );
        this.container.style.height = window.innerHeight - 50 + "px";
            
        // Camera
        /*
        this.camera = new THREE.PerspectiveCamera(
        45, // fov - Camera frustum vertical field of view
        this.container.offsetWidth / this.container.offsetHeight, // aspect - Camera frustum aspect ratio.
        1, // near - Camera frustum near plane.
        10000 // far - Camera frustum far plane.
        )
        this.camera.position.set( 0, 0, 150 );
        */

        // Orthographic Camera
        this.camera = new THREE.OrthographicCamera( 
            45 * window.innerWidth / window.innerHeight / - 2, // left - Camera frustum left plane
            45 * window.innerWidth / window.innerHeight / 2,  // right - Camera frustum right plane
            80 / 2, // top - Camera frustum top plane
            80 / - 2,  // bottom - Camera frustum bottom plane
            1, // near - Camera frustum near plane
            2000 // far - Camera frustum far plane
        );

        this.camera.position.set( 0, 0, 500 );

        // Scene
        this.scene = new THREE.Scene();
        this.scene.add(this.camera);

        // Materials
        const material = new THREE.MeshBasicMaterial({ color: 0xff00ff, side: THREE.DoubleSide });
        const wireframeMaterial = new THREE.MeshBasicMaterial( { color: 0x2d2d2d, wireframe: true, opacity:0.5  } );
        const pointMaterial = new THREE.PointsMaterial( { size: 0.25, color: 0x2d2d2d } )

        // Geometry
        const loader = new OBJLoader();

        // Group for shaded geometry
        let shadedGeometry = this.shadedGeometry;
        shadedGeometry.name = 'shadedGeometry';
        shadedGeometry.visible = false;

        // Group for wireframe geometry
        let wireframeGeometry = this.wireframeGeometry
        wireframeGeometry.name = 'wireframeGeometry';
        wireframeGeometry.visible = true;

        if(window.innerWidth < 500) {
          wireframeGeometry.scale.set(0.7, 0.7, 0.7);
        }

        // Group for point geometry
        let pointGeometry = this.pointGeometry;
        pointGeometry.name = 'pointGeometry';
        pointGeometry.visible = false;

        // Load text mesh
        loader.load(
            // Resource URL
            '../../3DModels/alfarok.obj',
            
            // Called when resource is loaded
            function ( object ) {
                let clones = new THREE.Object3D();
                let points = new THREE.Object3D();
                object.children.forEach(child => {
                    if(child.type === 'Mesh') {
                        // Orient to expected world xyz
                        child.rotation.x = -Math.PI / 2;
                        
                        // Clone for wireframe representation
                        let clone = child.clone();
                        clone.material = wireframeMaterial;
                        clones.add(clone);
                        
                        let vertices = clone.geometry.attributes.position;
                        let positions = vertices;
                        var geometry = new THREE.BufferGeometry();
                        geometry.setAttribute('position', positions);
                        var pointSet = new THREE.Points(geometry, pointMaterial);
                        pointSet.rotation.x = -Math.PI / 2;
                        points.add(pointSet);

                        child.material = material;
                        child.geometry.computeFaceNormals();
                        child.geometry.computeVertexNormals();
                        //child.material.shading = THREE.SmoothShading;
                        child.name = "alfarok";
                    }
                });
                
                shadedGeometry.add(object);
                wireframeGeometry.add(clones);
                pointGeometry.add(points);
            },
            // called when loading is in progresses
            function ( /*xhr*/ ) {
              //console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
            },
            // called when loading has errors
            function ( /*error*/ ) {
              //console.log( error );
            }
        );

        this.scene.add(shadedGeometry);
        this.scene.add(wireframeGeometry);
        this.scene.add(pointGeometry);

        // Build axis and grid
        //this.buildSceneHelpers();

        // Controls
        this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

        // Render
        this.$emit('setRendererSize', [this.container.offsetWidth, this.container.offsetHeight]);
        this.container.appendChild(this.renderer.domElement);

        // Events
        window.addEventListener('resize', this.onWindowResize, false);
        // Ensure window size is up-to-date
        window.dispatchEvent(new Event('resize'));
    },

    // Main render function
    render: function() {
      // Monitored code goes here
      //this.stats.begin();
      this.stats.update();

      this.shadedGeometry.rotation.x += 0.01;
      //this.shadedGeometry.rotation.y += 0.01;

      this.wireframeGeometry.rotation.x += 0.01;
      //this.wireframeGeometry.rotation.y += 0.01;

      this.pointGeometry.rotation.x += 0.01;
      //this.pointGeometry.rotation.y += 0.01;

      this.renderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
      //this.stats.end();
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function() {
      // Grid
      const size = 100;
      const step = 5;
      let gridHelper = new THREE.BufferGeometry();
      let gridMaterial = new THREE.LineBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.1 });
      
      const vertices = [];

      for (var i = -size; i <= size; i += step) {
        vertices.push(-size, 0, i);
        vertices.push(size, 0, i);
        vertices.push(i, 0, -size);
        vertices.push(i, 0, size);
      }

      gridHelper.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

      let gridLines = new THREE.LineSegments(gridHelper, gridMaterial, THREE.LineSegments);
      gridLines.name = "grid";
      this.scene.add(gridLines);
      
      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function(obj) {
      while(obj.children.length > 0){ 
        this.clearObj(obj.children[0])
        obj.remove(obj.children[0]);
      }
      if(obj.geometry) obj.geometry.dispose()

      if(obj.material){ 
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach(prop => {
          if(!obj.material[prop])
            return         
          if(typeof obj.material[prop].dispose === 'function')                                  
            obj.material[prop].dispose()                                                        
        })
        obj.material.dispose()
      }
    },   

    onWindowResize: function() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize( window.innerWidth, window.innerHeight );

        // Updating an orthographic camera
        this.camera.left = 80 * window.innerWidth / window.innerHeight / - 2;
        this.camera.right = 80 * window.innerWidth / window.innerHeight / 2;
        this.camera.top = 80 / 2;
        this.camera.bottom = 80 / - 2;
        this.camera.updateProjectionMatrix();
    },

    search: function() {
      var input = document.getElementById("search-bar-text");
      var filter = input.value.toUpperCase();

      // If filter is set make sure results are displayed
      if(filter.length > 0) {
        document.getElementById("search-results").style.display = 'block';
      }
      // Else hide
      else {
        document.getElementById("search-results").style.display = 'none';
      }

      // Clear previous results
      this.searchResults.innerHTML = "";

      let results = document.getElementById("search-results");

      // Search through sketch and note name for query string
      for (var i = 0; i < this.searchKeys.length; i++) {
        var key = this.searchKeys[i];

        if (key.toUpperCase().indexOf(filter) > -1) {
          let el = document.createElement('li');
          el.innerHTML = key
          
          el.onclick = function(event) { 
            var target = event.target.innerHTML;
            router.push({ path: target }).catch(err => {"Cannot navigate to current page"});
          }

          this.searchResults.appendChild(el);
        } 
      }
    }

    /*
    onMouseMove: function(event) {
        event.preventDefault();
        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
    }
    */
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
    this.render()
  },
  
  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener('resize', this.onWindowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  }

}

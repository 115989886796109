//
//
//
//
//
//

import CloudFlareDomainSetup from './Markdown/CloudFlare-Domain-Setup.md';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'cloudflare-domain-setup',
  components: { CloudFlareDomainSetup }
}

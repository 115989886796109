// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-MBPJZE5MFG" },
  appName: 'alfarok-dev',
  pageTrackerScreenviewEnabled: true
}, router);

new Vue({
  el: '#app',
  render: h => h(App),
  components: { App },
  template: '<App/>',
  router
}).$mount('#app')
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  // Tab name
  name: 'bounding-box-labeler',

  components: {
    VueSlider
  },
  
  // Objects stored on the component instance
  data() {
    return {
      container: null,
      img: null,
      canvas: null,
      ctx: null,
      size: 500,
      BUTTON: 0b01,
      mouseButtonIsDown: null,
      x1: null,
      y1: null,
      x2: null,
      y2: null,
      boxes: [],
      font: "16px sans-serif",
      classes: ["existing-class", "new-class"]
    }
  },

  watch: {
    size: function (val) {
      this.img.style.height = this.img.style.width = this.size.toString() + 'px';
      this.canvas.style.height = this.canvas.style.width = this.size.toString() + 'px';
    }
  },

  // Objects passed from parent (App)
  props: [],

  methods: {
    // Entry point
    init() {  
      // Update legend
      var legend = document.getElementById("legend")
      for(var i = 0; i < this.classes.length; i++){
        legend.innerHTML += "<br />" + this.classes[i];
      }

      // Define mouse button
      this.mouseButtonIsDown = buttons => (this.BUTTON & buttons) === this.BUTTON;
      
      // Set initial image and canvas
      this.img = document.getElementById('label-img');
      this.canvas = document.getElementById('canvas');
      this.ctx = this.canvas.getContext("2d");

      // Resize to coordinate with slider
      this.img.style.height = this.img.style.width = this.size.toString() + 'px';
      this.canvas.style.height = this.canvas.style.width = this.size.toString() + 'px';

      // Event handlers
      let mouseDown = evt => {
        evt.preventDefault();
        this.canvas.addEventListener("mousemove", mouseMove, false);

        let pos = this.getMousePos(this.canvas, evt);
        //console.log("DOWN");
        //console.log([pos.x, pos.y]);
        this.x1 = pos.x;
        this.y1 = pos.y;
      };

      let mouseMove = evt => {
        let pos = this.getMousePos(this.canvas, evt)

        // Clear previous
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        // Style
        this.ctx.lineWidth = 1;

        // Draw existing boxes
        for(var i = 0; i < this.boxes.length; i++)
        {
          var box = this.boxes[i];
          this.ctx.strokeStyle = "#FF00FF";
          this.ctx.strokeRect(box[0], box[1], box[2]-box[0], box[3]-box[1]);
          this.drawClassTag(box, this.classes[0], this.ctx.strokeStyle)
        }

        // Draw current dragging preview
        var box = [this.x1, this.y1, pos.x, pos.y];
        this.ctx.strokeStyle = "#FFFF00";
        this.ctx.strokeRect(box[0], box[1], box[2]-box[0], box[3]-box[1]);
        this.drawClassTag(box, this.classes[1], this.ctx.strokeStyle)
      };

      let mouseUp = evt => {
        evt.preventDefault();
        evt.currentTarget.removeEventListener("mousemove", mouseMove, false);

        let pos = this.getMousePos(this.canvas, evt);
        //console.log("UP");
        //console.log([pos.x, pos.y]);
        this.x2 = pos.x;
        this.y2 = pos.y;

        this.boxes.push([this.x1, this.y1, this.x2, this.y2]);
      };

      let mouseEnter = evt => {
        console.log("ENTER");
      };

      let mouseExit = evt => {
        console.log("EXIT");
      };

      // On window resize
      let updateContext = evt => {
          this.canvas = document.getElementById("canvas");
          this.ctx = this.canvas.getContext("2d");
      };

      // Register event handlers
      this.canvas.addEventListener("mousedown", mouseDown, false);
      this.canvas.addEventListener("mouseup", mouseUp, false);
      this.canvas.addEventListener("mouseout", mouseExit, false);
      this.canvas.addEventListener("mouseenter", mouseEnter, false);
      this.canvas.addEventListener("resize", updateContext, false);

      // Touch handlers (mobile/tablet)
      this.canvas.addEventListener("touchstart", this.touchHandler, true);
      this.canvas.addEventListener("touchmove", this.touchHandler, true);
      this.canvas.addEventListener("touchend", this.touchHandler, true);
      this.canvas.addEventListener("touchcancel", this.touchHandler, true);

      window.addEventListener('resize', this.onWindowResize, false);
    },

    drawClassTag(box, label, color)
    {
      // Draw tag
      this.ctx.font = this.font;
      this.ctx.textBaseline = "top";
      this.ctx.fillStyle = color;
      var textString = label;
      var textWidth = this.ctx.measureText(textString).width;
      var textHeight = parseInt(this.font, 10); // base 10
      this.ctx.fillRect(box[0], box[1], textWidth, textHeight);

      this.ctx.fillStyle = "#000000";
      this.ctx.fillText(textString, box[0], box[1]);
    },
    
    // Map touch to mouse
    touchHandler(event)
    {
        var touches = event.changedTouches,
            first = touches[0],
            type = "";
        switch(event.type)
        {
            case "touchstart": type = "mousedown"; break;
            case "touchmove":  type = "mousemove"; break;        
            case "touchend":   type = "mouseup";   break;
            default:           return;
        }

        // initMouseEvent(type, canBubble, cancelable, view, clickCount, 
        //                screenX, screenY, clientX, clientY, ctrlKey, 
        //                altKey, shiftKey, metaKey, button, relatedTarget);

        var simulatedEvent = document.createEvent("MouseEvent");
        simulatedEvent.initMouseEvent(type, true, true, window, 1, 
                                    first.screenX, first.screenY, 
                                    first.clientX, first.clientY, false, 
                                    false, false, false, 0/*left*/, null);

        first.target.dispatchEvent(simulatedEvent);
        event.preventDefault();
    },

    // Scale mouse position relatively to window size
    getMousePos(canvas, evt) {
        var rect = canvas.getBoundingClientRect();
        return {
            x: (evt.clientX - rect.left) / (rect.right - rect.left) * canvas.width,
            y: (evt.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height
        };
    }
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
  },
  
  // Dispose
  beforeDestroy: function () {

  }

}

//
//
//
//
//
//

import Kali from './Markdown/Kali.md';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'kali',
  components: { Kali }
}

//
//
//
//
//
//

import SampleMarkdown from './Markdown/SampleMarkdown.md';
import hljs from 'highlight.js/lib/highlight';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'markdown-template',
  components: { SampleMarkdown }
}

//
//
//
//

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GCodeLoader } from "three/examples/jsm/loaders/GCodeLoader.js";

export default {
  // Tab name
  name: "visualize-g-code",

  // Objects stored on the component instance
  data() {
    return {
      cube: null,
      scene: null,
      camera: null,
      orbitControls: null,
      frameId: null,
      container: null,
    };
  },

  // Objects passed from parent (App)
  props: ["renderer", "stats"],

  methods: {
    // Entry point
    init: function () {
      this.container = document.getElementById("container");
      this.container.style.height = window.innerHeight - 50 + "px";

      // Camera
      this.camera = new THREE.PerspectiveCamera(
        45, // fov - Camera frustum vertical field of view
        this.container.offsetWidth / this.container.offsetHeight, // aspect - Camera frustum aspect ratio.
        1, // near - Camera frustum near plane.
        10000 // far - Camera frustum far plane.
      );
      this.camera.position.set(0, 20, 100);

      // Geometry
      const geometry = new THREE.BoxGeometry(10, 10, 10);
      const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
      this.cube = new THREE.Mesh(geometry, material);

      // Scene
      this.scene = new THREE.Scene();
      this.scene.add(this.camera);

      // Build axis and grid
      this.buildSceneHelpers();

      // Load GCode
      const loader = new GCodeLoader();
      var sceneRef = this.scene;
      loader.load("../../GPU_Mount_Square.gcode", function (object) {
        object.position.set(-75, 0, 100);
        sceneRef.add(object);
      });

      // Controls
      this.orbitControls = new OrbitControls(
        this.camera,
        this.renderer.domElement
      );

      // Render
      this.$emit("setRendererSize", [
        this.container.offsetWidth,
        this.container.offsetHeight,
      ]);
      this.container.appendChild(this.renderer.domElement);

      // Events
      window.addEventListener("resize", this.onWindowResize, false);
      // Ensure window size is up-to-date
      window.dispatchEvent(new Event("resize"));
    },

    // Main render function
    render: function () {
      // Monitored code goes here
      //this.stats.begin();
      this.stats.update();
      this.renderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
      //this.stats.end();
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function () {
      // Grid
      const size = 100;
      const step = 5;
      let gridHelper = new THREE.BufferGeometry();
      let gridMaterial = new THREE.LineBasicMaterial({
        color: 0x000000,
        transparent: true,
        opacity: 0.1,
      });

      const vertices = [];

      for (var i = -size; i <= size; i += step) {
        vertices.push(-size, 0, i);
        vertices.push(size, 0, i);
        vertices.push(i, 0, -size);
        vertices.push(i, 0, size);
      }

      gridHelper.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(vertices, 3)
      );

      let gridLines = new THREE.LineSegments(
        gridHelper,
        gridMaterial,
        THREE.LineSegments
      );
      gridLines.name = "grid";
      this.scene.add(gridLines);

      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(0.001);
      axesHelper.rotateX(-Math.PI / 2);
      //this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function (obj) {
      while (obj.children.length > 0) {
        this.clearObj(obj.children[0]);
        obj.remove(obj.children[0]);
      }
      if (obj.geometry) obj.geometry.dispose();

      if (obj.material) {
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach((prop) => {
          if (!obj.material[prop]) return;
          if (typeof obj.material[prop].dispose === "function")
            obj.material[prop].dispose();
        });
        obj.material.dispose();
      }
    },

    onWindowResize: function () {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
  },

  // Function called when component is initially mounted
  mounted() {
    this.init();
    this.render();
  },

  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener("resize", this.onWindowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  },
};

//
//
//
//

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default {
  name: "flocking-particles",
  props: ["renderer"],
  data() {
    return {
      // Scene
      container: undefined,
      camera: new THREE.PerspectiveCamera(
        45, // fov - Camera frustum vertical field of view
        window.innerWidth / window.innerHeight, // aspect - Camera frustum aspect ratio.
        1, // near - Camera frustum near plane.
        10000 // far - Camera frustum far plane.
      ),
      scene: new THREE.Scene(),
      mouse: new THREE.Vector2(),
      windowHalfX: window.innerWidth / 2,
      windowHalfY: window.innerHeight / 2,

      // Initial Particle Grid
      boundingBox: undefined,
      boxSize: 400, // divide by 100 to define grid size
      spacing: 100,
      rows: 0,
      columns: 0,
      particles: 0,
      particle: 0,
      count: 0,

      // Physics Calcs
      flockVec: [],
      flockVel: [],
      speed: 0,
      factor: 0.5,
      mag: 0.5,

      // Scoreboard
      score: 0,
      remaining: 0,
      level: 1,
      menu: undefined,
      person: "No Scores",

      // Materials
      boxMaterial: new THREE.MeshBasicMaterial({
        color: 0xff00ff,
        transparent: true,
        opacity: 0.2,
        wireframe: false,
      }),
      particleMaterial: new THREE.MeshPhongMaterial({
        color: 0xbb00ff,
        specular: 0x111111,
        shininess: 100,
        side: THREE.DoubleSide,
        flatShading: false,
      }),
    };

  },
  methods: {
    init() {
      // Setup
      this.rows = this.columns = this.boxSize / this.spacing;
      this.remaining = this.rows * this.columns;

      // Camera
      this.camera.position.set(0, 0, (this.rows * 100) / 2);
      // Lighting
      const pointLight = new THREE.PointLight( 0xffffff );
      this.camera.add(pointLight);
      this.scene.add(this.camera);

      const ambientLight = new THREE.AmbientLight(0x000000);
      this.scene.add(ambientLight);

      // Initiate particle grid
      this.nextLevel();

      // Create bounding box
      const box = new THREE.BoxGeometry(
        this.boxSize,
        this.boxSize / 1.5,
        this.boxSize,
        1,
        1,
        1
      );

      // Bounding Box
      this.boundingBox = new THREE.Mesh(box, this.boxMaterial);
      //this.scene.add(this.boundingBox);
      const boxFrame = new THREE.BoxHelper(this.boundingBox);
      boxFrame.material.color.set(0x00ff00);
      this.scene.add(boxFrame);

      // Controls
      //this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

      // Renderer
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.container = document.getElementById( 'flock' );
      this.container.appendChild(this.renderer.domElement);

      // Events
      document.addEventListener("mousedown", this.onDocumentMouseDown);
      document.addEventListener("mousemove", this.onDocumentMouseMove, false);
      window.addEventListener("resize", this.onWindowResize, false);
    },

    render() {
      requestAnimationFrame(this.render);
      // Update camera position
      this.camera.position.x += (-this.mouse.x - this.camera.position.x) * 0.05;
      this.camera.position.y += (-this.mouse.y - this.camera.position.y) * 0.05;
      this.camera.lookAt(this.scene.position);
      this.camera.updateProjectionMatrix();

      this.moveParticle();

      this.renderer.render(this.scene, this.camera);
    },

    nextLevel() {
      this.getLevel();

      // Remove previous particles
      this.scene.traverse(function(element) {
        if (element.name === 'particle') {
          this.scene.remove(element);
        }
      })

      if (this.level != 0) {
        // Container for all particles
        this.particles = [];

        // Create Initial Grid
        let i = 0;

        this.flockVel = [];
        this.flockVec = [];

        for (
          this.RowNum = (-this.boxSize + this.spacing) / 2;
          this.RowNum <= this.boxSize / 2;
          this.RowNum += this.spacing
        )
          for (
            this.ColNum = (-this.boxSize + this.spacing) / 2;
            this.ColNum <= this.boxSize / 2;
            this.ColNum += this.spacing
          ) {
            const geom = new THREE.SphereGeometry(15, 32, 16);
            const mesh = new THREE.Mesh(geom, this.particleMaterial);
            mesh.position.set(this.RowNum, 0, this.ColNum);
            mesh.name = "particle";
            this.particle = this.particles[i++] = mesh;
            this.scene.add(this.particle);

            this.flockVel.push(this.wander(this.mag));
            this.flockVec.push(this.wander(this.mag));
          }
      }
    },

    updateScore() {
      this.score += 10;
      this.remaining -= 1;

      if (this.remaining == 0 && this.level == 6) {
        this.nextLevel();
      } else if (this.remaining == 0) {
        this.level += 1;
        this.nextLevel();
        this.remaining = this.rows * this.columns;
      }
    },

    getLevel() {
      // Intro params
      if (this.level == 0) {
        // THIS WAS THE LEGACY START MENU
        this.level += 1;
      }

      // Lvl 1 params
      else if (this.level == 1) {
        this.speed = 50;
        this.factor = 0.99;
        this.mag = 1;
      }

      // Lvl 2 params
      else if (this.level == 2) {
        this.speed = 200;
        this.factor = 0.75;
        this.mag = 2;
      }

      // Lvl 3 params
      else if (this.level == 3) {
        this.speed = 50;
        this.factor = 0.99;
        this.mag = 3;
      }

      // Lvl 4 params
      else if (this.level == 4) {
        this.speed = 50;
        this.factor = 0.99;
        this.mag = 1;
      }

      // Lvl 5 params
      else if (this.level == 5) {
        this.speed = 200;
        this.factor = 0.75;
        this.mag = 2;
      }

      // Reset
      else {
        this.score = 0;
        this.level = 1;
        this.speed = 50;
        this.factor = 0.99;
        this.mag = 1;
      }
    },

    wander(mag) {
      const ranX = Math.random();
      const ranY = Math.random();
      const ranZ = Math.random();
      const x = this.sc(ranX, this.speed);
      const y = this.sc(ranY, this.speed);
      const z = this.sc(ranZ, this.speed);

      let wanderVec = new THREE.Vector3(x, y, z);
      wanderVec = wanderVec.normalize();
      wanderVec = wanderVec.multiplyScalar(mag);
      return wanderVec;
    },

    sc(n, s) {
      return (n * 2 - 1) * s;
    },

    moveParticleByVec(p, v) {
      p.position.x += v.x;
      p.position.y += v.y;
      p.position.z += v.z;
      return p;
    },

    reverseDirection(p, d) {
      if (d == "x") {
        this.flockVec[p].x = -this.flockVec[p].x;
      } else if (d == "y") {
        this.flockVec[p].y = -this.flockVec[p].y;
      } else if (d == "z") {
        this.flockVec[p].z = -this.flockVec[p].z;
      }
    },

    moveParticle() {
      if (this.level != 0) {
        // Update particles
        let i = 0;

        for (let ix = 0; ix < this.rows; ix++) {
          for (let iy = 0; iy < this.columns; iy++) {
            const ran = Math.random();

            this.particle = this.particles[i];

            const x = this.particle.position.x;
            const y = this.particle.position.y;
            const z = this.particle.position.z;

            // Containment/bounce
            if (x > this.boxSize / 2 || x < -this.boxSize / 2) {
              this.reverseDirection(i, "x");
            } else if (
              y > this.boxSize / 2 / 1.5 ||
              y < -this.boxSize / 2 / 1.5
            ) {
              this.reverseDirection(i, "y");
            } else if (z > this.boxSize / 2 || z < -this.boxSize / 2) {
              this.reverseDirection(i, "z");
            } else {
              if (this.factor < ran) {
                this.flockVec[i] = this.wander(this.mag);
              }
            }

            this.moveParticleByVec(this.particle, this.flockVec[i]);

            i++;
          }
        }
      }
    },

    onDocumentMouseDown(event) {
      event.preventDefault();
      const mouse3D = new THREE.Vector3(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1,
        0.5
      );
      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse3D, this.camera);

      let intersects = [];

      if (this.level != 0) {
        intersects = raycaster.intersectObjects(this.particles);
        if (intersects.length > 0) {
          if (this.level == 4) {
            intersects[0].object.scale.x = intersects[0].object.scale.y =
              intersects[0].object.scale.x / 2;
            if (intersects[0].object.scale.x < 5) {
              intersects[0].object.scale.x = intersects[0].object.scale.y = 20;
              intersects[0].object.material.color.setHex(
                Math.random() * 0xffff00
              );
              intersects[0].object.visible = false;
              this.updateScore();
            }
          } else {
            intersects[0].object.scale.x = intersects[0].object.scale.y =
              intersects[0].object.scale.x * 4;
            if (intersects[0].object.scale.x > 80) {
              intersects[0].object.scale.x = intersects[0].object.scale.y = 20;
              intersects[0].object.material.color.setHex(
                Math.random() * 0x00ffff
              );
              intersects[0].object.visible = false;
              this.updateScore();
            }
          }
        }
      } else {
        intersects = raycaster.intersectObjects(this.menu);
        if (intersects.length > 0) {
          this.level += 1;
          this.nextLevel();
          this.scene.remove(this.menu[0]);
        }
      }
    },

    onDocumentMouseMove(event) {
      event.preventDefault();
      this.mouse.x = event.clientX - this.windowHalfX;
      this.mouse.y = event.clientY - this.windowHalfY;
    },

    onWindowResize() {
      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;

      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
  },
  mounted() {
    this.init();
    this.render();
  },
};

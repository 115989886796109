//
//
//
//

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";

export default {
  // Tab name
  name: "hex-grid",

  // Objects stored on the component instance
  data() {
    return {
      scene: null,
      camera: null,
      orbitControls: null,
      frameId: null,
      container: null,

      hexBuffers: null,
      hexPoints: null,
      midPoints: [],
    };
  },

  // Objects passed from parent (App)
  props: ["renderer", "stats"],

  methods: {
    // Entry point
    init: function () {
      this.container = document.getElementById("viewer");
      this.container.style.height = window.innerHeight - 50 + "px";

      // Orthographic Camera
      this.camera = new THREE.OrthographicCamera(
        (80 * window.innerWidth) / window.innerHeight / -2, // left - Camera frustum left plane
        (80 * window.innerWidth) / window.innerHeight / 2, // right - Camera frustum right plane
        80 / 2, // top - Camera frustum top plane
        80 / -2, // bottom - Camera frustum bottom plane
        1, // near - Camera frustum near plane
        2000 // far - Camera frustum far plane
      );
      this.camera.position.set(150, 150, 250);

      // Scene
      this.scene = new THREE.Scene();
      this.scene.add(this.camera);

      // Build axis
      //this.buildSceneHelpers();

      // Draw hex grid
      let grid = this.calculateGrid(20, 10, 10);
      let lines = this.drawGrid(grid);
      // OPTIONAL: center on view
      let box = new THREE.Box3().setFromObject(lines);
      let center = new THREE.Vector3();
      box.getCenter(center);
      lines.position.sub(center);
      this.scene.add(lines);

      // Tessellate
      this.tessellate(this.hexPoints, this.midPoints, center);

      // Controls
      this.orbitControls = new OrbitControls(
        this.camera,
        this.renderer.domElement
      );

      // Render
      this.$emit("setRendererSize", [
        this.container.offsetWidth,
        this.container.offsetHeight,
      ]);
      this.container.appendChild(this.renderer.domElement);

      // Events
      window.addEventListener("resize", this.onWindowResize, false);
      // Ensure window size is up-to-date
      window.dispatchEvent(new Event("resize"));
    },

    calculateGrid(xCount, yCount, length) {
      // Container for all points in hexgrid (not unique)
      let points = [];
      let buffers = [];

      let x = 0;
      let y = 0;
      const r = length / 2;
      const a = (2 * Math.PI) / 6;

      // For each column
      for (let col = 0; col < yCount; col++) {
        // For each row
        for (let row = 0; row < xCount; row++) {
          // Generate points for single hexagon
          let hex = [];
          let bufferFlat = [];
          for (let h = 0; h < 6; h++) {
            const px = x + r * Math.cos(a * h);
            let py = y + y + r * Math.sin(a * h);
            // Stagger
            if (row % 2 !== 0) {
              py = py + (Math.sqrt(3) * r) / 2;
            }
            const pz = 0;
            let p = new THREE.Vector3(px, py, pz);

            // debug
            /*
            const arrowHelper = new THREE.ArrowHelper(
              new THREE.Vector3(0, 0, 1),
              new THREE.Vector3(px, py, pz),
              5,
              0x0000ff
            );
            this.scene.add(arrowHelper);
            */

            bufferFlat.push(px, py, pz);
            hex.push(p);
          }

          // Close polygon
          bufferFlat.push(hex[0].x, hex[0].y, hex[0].z);

          // Calculate hex mids
          const midX = (hex[0].x + hex[3].x) / 2;
          const midY = (hex[0].y + hex[3].y) / 2;
          const midZ = (hex[0].z + hex[3].z) / 2;
          this.midPoints.push(new THREE.Vector3(midX, midY, midZ));

          // debug
          /*
          const arrowHelper = new THREE.ArrowHelper(
            new THREE.Vector3(0, 0, 1),
            new THREE.Vector3(midX, midY, midZ),
            5,
            0x00ffff
          );
          this.scene.add(arrowHelper);
          */

          // Add hexagon to larger array
          points.push(hex);
          buffers.push(new Float32Array(bufferFlat));

          // Step X/Y
          if (row + 1 < xCount) {
            x = x + r + r * Math.cos(a);
          } else {
            x = 0;
            y = y + r * Math.sin(a);
          }
        }
      }

      this.hexBuffers = buffers;
      this.hexPoints = points;
      return buffers;
    },

    drawGrid(buffers) {
      let hexagons = new THREE.Group();
      const lineMaterial = new LineMaterial({
        color: 0xffffff,
        linewidth: 0.008, // in world units with size attenuation, pixels otherwise
        dashed: false,
      });
      // For each hex
      for (let h = 0; h < buffers.length; h++) {
        // Create buffer
        let points = buffers[h];
        const geometryPoints = new LineGeometry();
        geometryPoints.setPositions(points);
        const hex = new Line2(geometryPoints, lineMaterial);
        // Draw
        hexagons.add(hex);
      }
      return hexagons;
    },

    tessellate(hexagons, midPoints, center) {
      //const material = new THREE.MeshBasicMaterial( { color: 0xff00ff, wireframe: true, opacity:0.75  } );
      const material = new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide,
        vertexColors: THREE.VertexColors,
      });

      // vertex shading coloring

      if (hexagons.length !== midPoints.length) {
        console.log("Error with tessellation alignment.");
        return;
      }

      for (let i = 0; i < hexagons.length; i++) {
        let buffer = [];
        // Tri 1
        buffer.push(hexagons[i][0].x, hexagons[i][0].y, hexagons[i][0].z);
        buffer.push(hexagons[i][1].x, hexagons[i][1].y, hexagons[i][1].z);
        buffer.push(midPoints[i].x, midPoints[i].y, midPoints[i].z);
        // Tri 2
        buffer.push(midPoints[i].x, midPoints[i].y, midPoints[i].z);
        buffer.push(hexagons[i][1].x, hexagons[i][1].y, hexagons[i][1].z);
        buffer.push(hexagons[i][2].x, hexagons[i][2].y, hexagons[i][2].z);
        // Tri 3
        buffer.push(hexagons[i][2].x, hexagons[i][2].y, hexagons[i][2].z);
        buffer.push(hexagons[i][3].x, hexagons[i][3].y, hexagons[i][3].z);
        buffer.push(midPoints[i].x, midPoints[i].y, midPoints[i].z);
        // Tri 4
        buffer.push(midPoints[i].x, midPoints[i].y, midPoints[i].z);
        buffer.push(hexagons[i][3].x, hexagons[i][3].y, hexagons[i][3].z);
        buffer.push(hexagons[i][4].x, hexagons[i][4].y, hexagons[i][4].z);
        // Tri 5
        buffer.push(hexagons[i][4].x, hexagons[i][4].y, hexagons[i][4].z);
        buffer.push(hexagons[i][5].x, hexagons[i][5].y, hexagons[i][5].z);
        buffer.push(midPoints[i].x, midPoints[i].y, midPoints[i].z);
        // Tri 6
        buffer.push(midPoints[i].x, midPoints[i].y, midPoints[i].z);
        buffer.push(hexagons[i][5].x, hexagons[i][5].y, hexagons[i][5].z);
        buffer.push(hexagons[i][0].x, hexagons[i][0].y, hexagons[i][0].z);

        var colors = new Uint8Array([
          // tri 1
          0, 0, 255, 255, 0, 0, 0, 255, 0,
          // tri 2
          0, 255, 0, 255, 0, 0, 0, 0, 255,
          // tri 3
          0, 0, 255, 255, 0, 0, 0, 255, 0,
          // tri 4
          0, 255, 0, 255, 0, 0, 0, 0, 255,
          // tri 5
          0, 0, 255, 255, 0, 0, 0, 255, 0,
          // tri 6
          0, 255, 0, 255, 0, 0, 0, 0, 255,
        ]);
        const vertices = new Float32Array(buffer);
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(vertices, 3)
        );
        geometry.setAttribute(
          "color",
          new THREE.BufferAttribute(colors, 3, true)
        );
        geometry.computeBoundingSphere();
        const mesh = new THREE.Mesh(geometry, material);
        // OPTIONAL: center on view
        mesh.position.sub(center);
        this.scene.add(mesh);
      }
    },

    // Main render function
    render: function () {
      // Monitored code goes here
      this.stats.begin();
      this.stats.update();
      this.renderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
      this.stats.end();
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function () {
      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateZ(0.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function (obj) {
      while (obj.children.length > 0) {
        this.clearObj(obj.children[0]);
        obj.remove(obj.children[0]);
      }
      if (obj.geometry) obj.geometry.dispose();

      if (obj.material) {
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach((prop) => {
          if (!obj.material[prop]) return;
          if (typeof obj.material[prop].dispose === "function")
            obj.material[prop].dispose();
        });
        obj.material.dispose();
      }
    },
    onWindowResize: function () {
      // Updating an orthographic camera
      this.camera.left = (80 * window.innerWidth) / window.innerHeight / -2;
      this.camera.right = (80 * window.innerWidth) / window.innerHeight / 2;
      this.camera.top = 80 / 2;
      this.camera.bottom = 80 / -2;
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.camera.updateProjectionMatrix();
    },
  },

  // Function called when component is initially mounted
  mounted() {
    this.init();
    this.render();
  },

  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener("resize", this.onWindowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var THREE = require('three');
var Stats = require('stats.js');

import router from './../router'
import sketches from '@/components/sketches'
import notes from '@/components/notes'

export default {
  name: 'MainViewer',
  data: function () {
    return {
      allSketches: sketches,
      allNotes: notes,
      webGLRenderer: new THREE.WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true }),
      stats: new Stats()
    }
  },
  methods: {
    init() {
      // Stats
      this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
      this.stats.dom.id = "STATS"
      this.stats.domElement.style.cssText = 'position:fixed;bottom:0px;right:0px;';
      
      // Display stats
      //document.body.appendChild( this.stats.dom );

      // Renderer
      this.webGLRenderer.setClearColor(0xffffff, 1);
    },

    // TODO - the following 4 methods should be condensed
    updateSketch(sketch, name){
      router.push({ path: '../sketches/' + name }).catch(err => {"Cannot navigate to current page"});
      this.toggleNav('sketchNav');
      document.getElementById('searchSketches').value = '';
      this.searchFilter('searchSketches', 'sketchesList');
    },

    updateNote(note, name){
      router.push({ path: '../notes/' + name }).catch(err => {"Cannot navigate to current page"});
      this.toggleNav('noteNav');
      document.getElementById('searchNotes').value = '';
      this.searchFilter('searchNotes', 'notesList');
    },
    
    contact() {
      router.push({ path: '../contact' }).catch(err => {"Cannot navigate to current page"});
      document.getElementById('noteNav').style.width = "0px";
      document.getElementById('sketchNav').style.width = "0px";
    },

    // Very basic search
    searchFilter(searchInput, queryList) {
      var input = document.getElementById(searchInput);
      var filter = input.value.toUpperCase();
      var ul = document.getElementById(queryList);
      var buttons = ul.children;

      // Loop through all list items, hide those that don't match the search query
      for (var i = 0; i < buttons.length; i++) {
        var button = buttons[i];
        var txtValue = button.textContent || button.innerText;

        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          buttons[i].style.display = "";
        } 
        else {
          buttons[i].style.display = "none";
        }
      }
    },

    toggleNav(divId) {
      var currentWidth = document.getElementById(divId).style.width;

      if(divId === 'sketchNav')
      {
        if(currentWidth == "0px" || currentWidth == ""){
          document.getElementById(divId).style.width = "250px";
          document.getElementById('noteNav').style.width = "0px";
        }
        else{
          document.getElementById(divId).style.width = "0px";
        }
        document.getElementById('searchSketches').value = '';
        this.searchFilter('searchSketches', 'sketchesList');
      }
      else if(divId === 'noteNav'){
        if(currentWidth == "0px" || currentWidth == ""){
          document.getElementById(divId).style.width = "250px";
          document.getElementById('sketchNav').style.width = "0px";
        }
        else{
          document.getElementById(divId).style.width = "0px";
        }
        document.getElementById('searchNotes').value = '';
        this.searchFilter('searchNotes', 'notesList');
      }
      
      // Raise window resize event to update camera and renderer
      window.dispatchEvent(new Event('resize'));
    },

    // Update renderer size via an emitted event upward from a child component
    setRendererSize(value) {
      this.webGLRenderer.setSize(value[0], value[1]);
    }
  },
  mounted() {
    this.init();
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

import labels from './../../assets/LabelMaps/coco_ssd_mobilenet_v1_1.0_quant_2018_06_29.json'

export default {
  // Tab name
  name: 'tensorflow-object-detection',

  components: {
    VueSlider
  },
  
  // Objects stored on the component instance
  data() {
    return {
      container: null,
      img: null,
      img2: null,
      confidence: 0.7
    }
  },

  // Objects passed from parent (App)
  props: [],

  methods: {
    // Entry point
    async init() {  
      this.container = document.getElementById( 'viewer' );
      this.img = document.getElementById('cora');
      this.img2 = document.getElementById('traffic');
    },

    renderPredictions(predictions, scores, classes, ctx) {
      // Filter predictions that have a confidence values under x%
      let filterPredictions = [];
      let filterClasses = [];
      
      for(var i = 0; i < scores[0].length; i++) {
        if(scores[0][i] > this.confidence) {
          filterPredictions.push(predictions[0][i])
          filterClasses.push(classes[0][i])
        }
      }

      // Clear any previous results
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Font options
      const font = "16px sans-serif";
      ctx.font = font;
      ctx.textBaseline = "top";
      
      for(var i = 0; i < filterPredictions.length; i++) {
        const x = filterPredictions[i][1] * 300; // left, 0
        const y = filterPredictions[i][0] * 300; // top, 0
        const width = (filterPredictions[i][1] + filterPredictions[i][3]) * 300; // left + right = width
        const height = (filterPredictions[i][0] + filterPredictions[i][2]) * 300; // top + bottom = height
        
        // Draw the bounding box
        ctx.strokeStyle = "#FF00FF";
        ctx.lineWidth = 2;
        ctx.strokeRect(x, y, width, height);
        
        // Draw the label background
        ctx.fillStyle = "#FF00FF";
        const textString = labels[(filterClasses[i] + 1).toString()];
        const textWidth = ctx.measureText(textString).width;
        const textHeight = parseInt(font, 10); // base 10
        ctx.fillRect(x, y, textWidth + 2, textHeight + 2);
      };

      // Display labels
      for(var i = 0; i < filterPredictions.length; i++) {
        const x = filterPredictions[i][1] * 300;
        const y = filterPredictions[i][0] * 300;
        // Draw the text last to ensure it's on top.
        ctx.fillStyle = "#000000";
        ctx.fillText(labels[(filterClasses[i] + 1).toString()], x, y);
      }
    },

    // Make a request to ML model
    runModel(id) {
      console.log("calling ML model...");
      document.body.style.cursor = 'wait';

      var img = document.getElementById(id);

      // Convert INPUT div to base64 encoded image
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d")
      ctx.drawImage(img, 0, 0);

      var img = canvas.toDataURL("image/png");
      img = img.replace(/^data:image\/(png|jpg);base64,/, "");
      
      // Send post request to 
      var xhr = new XMLHttpRequest();
      var url = "https://sxb4g5tu2i.execute-api.us-east-1.amazonaws.com/default/tensorflow-object-detection";     
      xhr.open("POST", url, true);
      xhr.timeout = 60000; // 2 min
      
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");

      let context = this;

      let drawCanvas;

      if(id == "cora"){
        drawCanvas = document.getElementById( 'canvas' ).getContext("2d");
      }
      else {
        drawCanvas = document.getElementById( 'canvas2' ).getContext("2d");
      }
      
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          var json = JSON.parse(xhr.responseText);
          console.log("Request Complete:");
          var output = JSON.parse(json.body);

          var locations = output.Locations;
          var classes = output.Classes;
          var scores = output.Scores;
          var count = output.Count;

          /* DEBUG
          // Top, left, bottom, right
          console.log("Locations: ");
          console.log(locations);

          // Indexed to labelmap.txt file
          console.log("Classes: ");
          console.log(classes);

          // Confidence between 0 and 1
          console.log("Scores: ");
          console.log(scores);

          // Number of detection results
          console.log("Count: ");
          console.log(count);
          */

          // Draw boundaries and labels
          context.renderPredictions(locations, scores, classes, drawCanvas);

          // Restore default cursor
          document.body.style.cursor = 'default';
        }
        else {
          console.log("request polling...");
          //console.log(xhr.readyState);
          //console.log(xhr.status);
        }
      };
      
      var data = JSON.stringify( { "body": img } );
      xhr.send(data);
    }
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
  },
  
  // Dispose
  beforeDestroy: function () {

  }

}

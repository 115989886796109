//
//
//
//
//
//
//
//

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

export default {
    // Tab name
    name: 'drag-and-drop',
  
    // Objects stored on the component instance
    data() {
        return {
            scene: null,
            camera: null,
            orbitControls: null,
            frameId: null,
            container: null,
            material: null
        }
    },

    // Objects passed from parent (App)
    props: ['renderer', 'stats'],

    methods: {
        // Entry point
        init: function() {  
            this.container = document.getElementById( 'viewer' );
            this.container.style.height = window.innerHeight - 50 + "px";
          
            // Camera
            this.camera = new THREE.PerspectiveCamera(
                45, // fov - Camera frustum vertical field of view
                this.container.offsetWidth / this.container.offsetHeight, // aspect - Camera frustum aspect ratio.
                1, // near - Camera frustum near plane.
                10000 // far - Camera frustum far plane.
            )
            this.camera.position.set( 0, 20, 100 );
    
            // Scene
            this.scene = new THREE.Scene();
            this.scene.add(this.camera);

            // Light
            let pointLight = new THREE.PointLight( 0xffffff );
            pointLight.position.set(0,50,0);
            this.camera.add(pointLight);

            // Define default material
            this.material = new THREE.MeshBasicMaterial({ color: 0xff00ff, side: THREE.DoubleSide });

            // Build axis and grid
            this.buildSceneHelpers();
            
            // Controls
            this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

            // Render
            this.$emit('setRendererSize', [this.container.offsetWidth, this.container.offsetHeight]);
            this.container.appendChild(this.renderer.domElement);
      
            // Events
            window.addEventListener('resize', this.onWindowResize, false);
            window.addEventListener("dragover", function(event) {
            event.preventDefault();
            });

            // File drop
            window.addEventListener('drop', (event)=>{ 
                event.preventDefault();
                var files = event.dataTransfer.files

                this.importFiles(files, this.scene);

            }, false);

            // Ensure window size is up-to-date
            window.dispatchEvent(new Event('resize'));
        },

        importFiles(files, scene) {
            if ( files.length > 0 ) {
                var filesMap = this.createFileMap( files );

                var manager = new THREE.LoadingManager();
                manager.setURLModifier( function ( url ) {

                    url = url.replace( /^(\.?\/)/, '' ); // remove './'

                    var file = filesMap[ url ];

                    if ( file ) {
                        console.log( 'Loading', url );
                        return URL.createObjectURL( file );
                    }

                    return url;
                });

                for ( var i = 0; i < files.length; i ++ ) {
                    this.loadFile( files[ i ], manager, scene );
                }
            }
        },

        createFileMap( files ) {
            var map = {};

            for ( var i = 0; i < files.length; i ++ ) {
                var file = files[ i ];
                map[ file.name ] = file;
            }

            return map;
        },

        loadFile( file, manager, scene) {
            var filename = file.name;
            var extension = filename.split( '.' ).pop().toLowerCase();

            var reader = new FileReader();
            reader.addEventListener( 'progress', function ( event ) {
                var progress = Math.floor( ( event.loaded / event.total ) * 100 ) + '%';
                console.log( 'Loading', filename, progress );
            } );

            switch ( extension ) {
                case 'obj':
                    reader.addEventListener( 'load', async function ( event ) {
                        var contents = event.target.result;

                        var { OBJLoader } = await import('three/examples/jsm/loaders/OBJLoader.js')

                        var object = new OBJLoader().parse( contents );
                        object.name = filename;
                        object.material = this.material;
                        object.rotation.x = -Math.PI / 2;

                        object.traverse( function ( child ) {
                            // "Smooth" mesh normals
                            if ( child instanceof THREE.Mesh ) {
                                child.geometry.computeVertexNormals()
                            }
                        });

                        scene.add(object);

                    }, false );
                    
                    reader.readAsText( file );

                    break;
                
                case '3dm':
                    reader.addEventListener( 'load', async function ( event ) {
                        var contents = event.target.result;

                        var { Rhino3dmLoader } = await import( 'three/examples/jsm/loaders/3DMLoader.js' );

                        var loader = new Rhino3dmLoader();
                        // TODO - why doesn't this local reference work?
                        //loader.setLibraryPath('three/examples/jsm/libs/rhino3dm/');
                        loader.setLibraryPath('https://cdn.jsdelivr.net/npm/rhino3dm@0.15.0-beta/');
                        
                        loader.parse(contents, function(object) {
                            object.traverse( function ( child ) {
                                if ( child instanceof THREE.Mesh ) {
                                    child.geometry.computeVertexNormals()
                                    child.rotation.x = -Math.PI / 2;
                                    child.material = new THREE.MeshBasicMaterial({ color: 0xff00ff, side: THREE.DoubleSide });
                                    scene.add(child);
                                }
                                // TODO - other geometry types
                            });
                        });

                    }, false);

                    reader.readAsArrayBuffer( file );

                    break;
                default:
                    alert( 
                        'Unsupported file format (' + extension +  '). \n'+
                        'OBJ & STL are currently supported.'
                    );
                    break;
            }
        },

        // Main render function
        render: function() {
            // Monitored code goes here
            //this.stats.begin();
            this.stats.update();
            this.renderer.render(this.scene, this.camera);
            this.frameId = requestAnimationFrame(this.render);
            //this.stats.end();
        },

        // Helper function to build grid and axes helper
        buildSceneHelpers: function() {
            // Grid
            const size = 100;
            const step = 5;
            let gridHelper = new THREE.BufferGeometry();
            let gridMaterial = new THREE.LineBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.1 });
            
            const vertices = [];

            for (var i = -size; i <= size; i += step) {
                vertices.push(-size, 0, i);
                vertices.push(size, 0, i);
                vertices.push(i, 0, -size);
                vertices.push(i, 0, size);
            }

            gridHelper.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

            let gridLines = new THREE.LineSegments(gridHelper, gridMaterial, THREE.LineSegments);
            gridLines.name = "grid";
            this.scene.add(gridLines);
      
            // Axes
            let axesHelper = new THREE.AxesHelper(100);
            axesHelper.name = "axes";
            axesHelper.translateY(.001);
            axesHelper.rotateX(-Math.PI / 2);
            this.scene.add(axesHelper);
        },

        // Attempt to remove any previously existing canvas or stats
        clearObj: function(obj) {
            while(obj.children.length > 0){ 
                this.clearObj(obj.children[0])
                obj.remove(obj.children[0]);
            }
            
            if(obj.geometry) obj.geometry.dispose()

            if(obj.material){ 
                //in case of map, bumpMap, normalMap, envMap ...
                Object.keys(obj.material).forEach(prop => {
                    if(!obj.material[prop])
                        return

                    if(typeof obj.material[prop].dispose === 'function')                                  
                        obj.material[prop].dispose()                                                        
                })

                obj.material.dispose()
            }
        },   

        onWindowResize: function() {
            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize( window.innerWidth, window.innerHeight );
        },
    },

    // Function called when component is initially mounted
    mounted() {
        this.init()
        this.render()
    },
  
    // Dispose
    beforeDestroy: function () {
        // Unsubscribe
        window.removeEventListener('resize', this.onWindowResize, false);

        // Cancel animation loop
        cancelAnimationFrame(this.frameId);

        //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
        this.clearObj(this.scene);

        // TODO - research dispose method?
        this.camera = null;
        this.orbitControls = null;
    }

}

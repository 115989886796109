//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

// Import helper from three examples
import { ConvexGeometry } from './../../../node_modules/three/examples/jsm/geometries/ConvexGeometry.js';

export default {
  // Tab name
  name: 'webgl-particles-template',
  
  // Objects stored on the component instance
  data() {
    return {
      scene: null,
      camera: null,
      orbitControls: null,
      frameId: null,
      container: null,
      speed: 0.1,
      count: 1000,
      radius: 120,
      positions: [],
      colors: [],
      size: 2,
      sizes: [],
      geometry: null,
      textmat: null,
      particles: null,
      wireframe: null
    }
  },

  // Objects passed from parent (App)
  props: ['renderer', 'stats'],

  methods: {
    // Entry point
    init: function() {  
      this.container = document.getElementById( 'viewer' );
      this.container.style.height = window.innerHeight - 50 + "px";
          
      // Perspective Camera
      /*
      this.camera = new THREE.PerspectiveCamera(
        45, // fov - Camera frustum vertical field of view
        this.container.offsetWidth / this.container.offsetHeight, // aspect - Camera frustum aspect ratio.
        1, // near - Camera frustum near plane.
        10000 // far - Camera frustum far plane.
      )
      this.camera.position.set( 0, 20, 100 );
      */

      // Orthographic Camera
      this.camera = new THREE.OrthographicCamera( 
        window.innerWidth / - 2, // left - Camera frustum left plane
        window.innerWidth / 2,  // right - Camera frustum right plane
        window.innerHeight / 2, // top - Camera frustum top plane
        window.innerHeight / - 2,  // bottom - Camera frustum bottom plane
        0.5, // near - Camera frustum near plane
        1000 // far - Camera frustum far plane
      )
      this.camera.position.set( 0, 0, 200 );
    
      // Scene
      this.scene = new THREE.Scene();
      this.scene.add(this.camera);

      // Build geometry
      this.generatePoints();

      // Controls
      this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

      // Build axis and grid
      //this.buildSceneHelpers();

      // Render
      this.$emit('setRendererSize', [this.container.offsetWidth, this.container.offsetHeight]);
      this.container.appendChild(this.renderer.domElement);
      
      // Events
      window.addEventListener('resize', this.onWindowResize, false);
      // Ensure window size is up-to-date
      window.dispatchEvent(new Event('resize'));
    },

    generatePoints: function() {
      // Load sprint
      const loader = new THREE.TextureLoader();
      this.textMap = loader.load('/ball.png');

      var uniforms = {
        pointTexture: { value: this.textMap }
      };

      const shaderMaterial = new THREE.ShaderMaterial( {
        uniforms: uniforms,
        vertexShader: document.getElementById( 'vertexshader' ).textContent,
        fragmentShader: document.getElementById( 'fragmentshader' ).textContent,
        depthTest: false,
        transparent: true,
        vertexColors: true
      } );

      // Points
      const color = new THREE.Color();

      for ( let i = 0; i < this.count; i ++ ) {
        this.positions.push( ( Math.random() * 2 - 1 ) * this.radius ); // x
        this.positions.push( ( Math.random() * 2 - 1 ) * this.radius ); // y
        this.positions.push( ( Math.random() * 2 - 1 ) * this.radius ); // z
        //this.positions.push( 0 ); // z

        color.setHSL( i / this.count, 1.0, 0.5 );
 
        this.colors.push( color.r, color.g, color.b );
        this.sizes.push( this.size );
      }

      this.geometry = new THREE.BufferGeometry();
      this.geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( this.positions, 3 ) );
			this.geometry.setAttribute( 'color', new THREE.Float32BufferAttribute(this.colors, 3 ) );
			this.geometry.setAttribute( 'size', new THREE.Float32BufferAttribute( this.sizes, 1 ).setUsage( THREE.DynamicDrawUsage ) );
      this.geometry.attributes.position.needsUpdate = true;

			this.particles = new THREE.Points(this.geometry, shaderMaterial );
      this.scene.add(this.particles);

      // Convert buffer positions to vec3
      var vecs = []
      for(var i=0; i<this.positions.length; i+=3){
        var vec = new THREE.Vector3(this.positions[i], this.positions[i+1], this.positions[i+2])
        vecs.push(vec)
      }

      // Get convex hull for 2d point array
      var ch = new ConvexGeometry(vecs);

      const meshMaterial = new THREE.MeshLambertMaterial( {
        color: 0xffffff,
        opacity: 0.5,
        transparent: false
      } );

      var frame = new THREE.EdgesGeometry( ch );
      var frameMaterial = new THREE.LineBasicMaterial( { color: 0xff00ff, linewidth: 1 } );
      this.wireframe = new THREE.LineSegments( frame, frameMaterial );
      this.scene.add( this.wireframe );
    },

    // Main render function
    render: function() {
      // Monitored code goes here
      this.stats.begin();
      this.stats.update();

      // Rotate over x axis (just for fun)
      const time = Date.now() * 0.005;
      this.particles.rotation.x = this.particles.rotation.y = this.speed * time;
      this.wireframe.rotation.x = this.wireframe.rotation.y = this.speed * time;
      const sizes = this.geometry.attributes.size.array;
      for ( let i = 0; i < this.particles; i ++ ) {
        sizes[ i ] = 10 * ( 1 + Math.sin( 0.1 * i + time ) );
      }
      this.geometry.attributes.size.needsUpdate = true;

      // Render
      this.renderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
      this.stats.end();
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function() {
      // Grid
      const size = 100;
      const step = 5;
      let gridHelper = new THREE.BufferGeometry();
      let gridMaterial = new THREE.LineBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.1 });
      
      const vertices = [];

      for (var i = -size; i <= size; i += step) {
        vertices.push(-size, 0, i);
        vertices.push(size, 0, i);
        vertices.push(i, 0, -size);
        vertices.push(i, 0, size);
      }

      gridHelper.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

      let gridLines = new THREE.LineSegments(gridHelper, gridMaterial, THREE.LineSegments);
      gridLines.name = "grid";
      this.scene.add(gridLines);
      
      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function(obj) {
      while(obj.children.length > 0){ 
        this.clearObj(obj.children[0])
        obj.remove(obj.children[0]);
      }
      if(obj.geometry) obj.geometry.dispose()

      if(obj.material){ 
        // In case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach(prop => {
          if(!obj.material[prop])
            return         
          if(typeof obj.material[prop].dispose === 'function')                                  
            obj.material[prop].dispose()                                                        
        })
        obj.material.dispose()
      }
    },   

    onWindowResize: function() {
      // Update perspective camera
      //this.camera.aspect = window.innerWidth / window.innerHeight;
      //this.camera.updateProjectionMatrix();

      // Updating an orthographic camera
      this.camera.left = window.innerWidth / - 2, // left - Camera frustum left plane
      this.camera.right = window.innerWidth / 2,  // right - Camera frustum right plane
      this.camera.top = window.innerHeight / 2, // top - Camera frustum top plane
      this.camera.bottom = window.innerHeight / - 2,  // bottom - Camera frustum bottom plane
      this.camera.updateProjectionMatrix();

      this.renderer.setSize( window.innerWidth, window.innerHeight );
    },
    
    onMouseMove: function(event) {
      event.preventDefault();
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
    }
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
    this.render()
  },
  
  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener('resize', this.onWindowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  }

}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ApiPromise, WsProvider } from "@polkadot/api";
import Identicon from "@polkadot/vue-identicon";

export default {
  name: "polkadot-js-rpc",

  components: { Identicon },

  data() {
    return {
      unsubscribe: null,
      console: null,
      dotHash: "5GrwvaEF5zXb26Fz9rcQpDWS57CtERHpNehXCPcNoHGKutQY",
    };
  },

  methods: {
    async init() {
      // Retrieve console for logging
      this.console = document.getElementById("console");
      let li = document.createElement("li");
      li.innerHTML = `Connecting to chain...`;
      this.console.appendChild(li);

      // Connect
      const wsProvider = new WsProvider("wss://rpc.polkadot.io");
      const api = await ApiPromise.create({ provider: wsProvider });

      // Only display a couple, then unsubscribe
      let count = 0;

      // Subscribe to the new headers on-chain. The callback is fired when new headers
      // are found, the call itself returns a promise with a subscription that can be
      // used to unsubscribe from the newHead subscription

      // Subscribe to all new headers (with extended info)
      this.unsubscribe = await api.derive.chain.subscribeNewHeads((header) => {
        //this.unsubscribe = await api.rpc.chain.subscribeNewHeads((header) => {
        let li = document.createElement("li");
        li.innerHTML = `Chain is at block: #${header.number}`;
        this.dotHash = header.author.toHex();
        this.console.appendChild(li);

        if (++count === 256) {
          this.unsubscribe();
          process.exit(0);
        }
      });
    },
  },

  mounted() {
    this.init();
  },

  // Dispose
  beforeDestroy: function () {
    this.unsubscribe();
    process.exit(0);
  },
};

//
//
//
//
//
//

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

export default {
  // Tab name
  name: 'canvas-texture-labels',

  // Objects stored on the component instance
  data() {
    return {
      scene: null,
      camera: null,
      orbitControls: null,
      frameId: null,
      container: null,
      mesh: null
    }
  },

  // Objects passed from parent (App)
  props: ['renderer', 'stats'],

  methods: {
    // Entry point
    init: function () {
      this.container = document.getElementById('viewer');
      this.container.style.height = window.innerHeight - 50 + "px";

      // Camera
      this.camera = new THREE.OrthographicCamera(
        80 * window.innerWidth / window.innerHeight / - 2, // left - Camera frustum left plane
        80 * window.innerWidth / window.innerHeight / 2,  // right - Camera frustum right plane
        80 / 2, // top - Camera frustum top plane
        80 / - 2,  // bottom - Camera frustum bottom plane
        1, // near - Camera frustum near plane
        2000 // far - Camera frustum far plane
      )
      this.camera.position.set(0, 0, 500);

      const textureSize = 512;
      const canvas = document.createElement('canvas');
      canvas.width = canvas.height = textureSize;
      const ctx = canvas.getContext('2d');

      // Set background color
      const backgroundPath = new Path2D();
      backgroundPath.rect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgb(255, 203, 5)'; // yellow
      ctx.fill(backgroundPath);

      // Set text
      ctx.font = `${textureSize / 4}px Arial`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = 'red';
      ctx.fillText('label', textureSize / 2, textureSize / 2);
      const texture = new THREE.CanvasTexture(canvas);

      // Materials
      const textureMaterial = new THREE.MeshBasicMaterial({ map: texture });
      const colorMaterial = new THREE.MeshBasicMaterial({ color: 0x01045c }); // blue
      
      // Geometry
      const geometry = new THREE.BoxBufferGeometry(25, 25, 25);
      this.mesh = new THREE.Mesh(geometry, [colorMaterial, colorMaterial, colorMaterial, colorMaterial, textureMaterial, colorMaterial]);
      const wire = new THREE.Mesh(
        geometry,
        new THREE.MeshBasicMaterial({ color: 0x000000, wireframe: true })
      );
      this.mesh.add(wire);

      // Scene
      this.scene = new THREE.Scene();
      this.scene.add(this.camera, this.mesh);

      // Build axis and grid
      //this.buildSceneHelpers();

      // Controls
      this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

      // Render
      this.$emit('setRendererSize', [this.container.offsetWidth, this.container.offsetHeight]);
      this.container.appendChild(this.renderer.domElement);

      // Events
      window.addEventListener('resize', this.onWindowResize, false);
      // Ensure window size is up-to-date
      window.dispatchEvent(new Event('resize'));
    },

    // Main render function
    render: function () {
      // Monitored code goes here
      //this.stats.begin();
      this.stats.update();
      this.mesh.rotation.x += 0.01;
      this.mesh.rotation.y += 0.01;
      this.renderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
      //this.stats.end();
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function () {
      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function (obj) {
      while (obj.children.length > 0) {
        this.clearObj(obj.children[0])
        obj.remove(obj.children[0]);
      }
      if (obj.geometry) obj.geometry.dispose()

      if (obj.material) {
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach(prop => {
          if (!obj.material[prop])
            return
          if (typeof obj.material[prop].dispose === 'function')
            obj.material[prop].dispose()
        })
        obj.material.dispose()
      }
    },

    onWindowResize: function () {
      this.camera.left = 80 * window.innerWidth / window.innerHeight / - 2;
      this.camera.right = 80 * window.innerWidth / window.innerHeight / 2;
      this.camera.top = 80 / 2;
      this.camera.bottom = 80 / - 2;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    }
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
    this.render()
  },

  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener('resize', this.onWindowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  }

}

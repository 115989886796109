//
//
//
//
//
//


import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'

export default {
  // Tab name
  name: 'topo-loader',
  
  // Objects stored on the component instance
  data() {
    return {
      cube: null,
      scene: null,
      camera: null,
      orbitControls: null,
      frameId: null,
      container: null,
      shadedGeometry: new THREE.Group(),
      wireframeGeometry: new THREE.Group(),
      pointGeometry: new THREE.Group(),
    }
  },

  // Objects passed from parent (App)
  props: ['renderer', 'stats'],

  methods: {
    // Entry point
    init: function() {  
        this.container = document.getElementById( 'viewer' );
        this.container.style.height = window.innerHeight - 50 + "px";

        // Orthographic Camera
        this.camera = new THREE.OrthographicCamera( 
            45 * window.innerWidth / window.innerHeight / - 2, // left - Camera frustum left plane
            45 * window.innerWidth / window.innerHeight / 2,  // right - Camera frustum right plane
            80 / 2, // top - Camera frustum top plane
            80 / - 2,  // bottom - Camera frustum bottom plane
            1, // near - Camera frustum near plane
            2000 // far - Camera frustum far plane
        );
        this.camera.position.set( 100, 100, 500 );

        // Scene
        this.scene = new THREE.Scene();
        this.scene.add(this.camera);

        // Materials
        const material = new THREE.MeshBasicMaterial({ color: 0xff00ff, side: THREE.DoubleSide });
        const wireframeMaterial = new THREE.MeshBasicMaterial( { color: 0x2d2d2d, wireframe: true, opacity:0.25  } );
        const pointMaterial = new THREE.PointsMaterial( { size: 1.5, color: 0xffffff } )

        // Geometry
        const loader = new OBJLoader();

        // Group for shaded geometry
        let shadedGeometry = this.shadedGeometry;
        shadedGeometry.name = 'shadedGeometry';
        shadedGeometry.visible = true;

        // Group for wireframe geometry
        let wireframeGeometry = this.wireframeGeometry
        wireframeGeometry.name = 'wireframeGeometry';
        wireframeGeometry.visible = false;

        if(window.innerWidth < 500) {
          wireframeGeometry.scale.set(0.7, 0.7, 0.7);
        }

        // Group for point geometry
        let pointGeometry = this.pointGeometry;
        pointGeometry.name = 'pointGeometry';
        pointGeometry.visible = true;

        // Load text mesh
        loader.load(
            // Resource URL
            '../../3DModels/topo-small.obj',
            
            // Called when resource is loaded
            function ( object ) {
                let clones = new THREE.Object3D();
                let points = new THREE.Object3D();
                object.children.forEach(child => {
                    if(child.type === 'Mesh') {
                        // Orient to expected world xyz
                        child.rotation.x = -Math.PI / 2;
                        
                        // Clone for wireframe representation
                        let clone = child.clone();
                        clone.material = wireframeMaterial;
                        clones.add(clone);
                        
                        let vertices = clone.geometry.attributes.position;
                        let positions = vertices;
                        var geometry = new THREE.BufferGeometry();
                        geometry.setAttribute('position', positions);
                        var pointSet = new THREE.Points(geometry, pointMaterial);
                        pointSet.rotation.x = -Math.PI / 2;
                        points.add(pointSet);

                        child.material = material;
                        child.geometry.computeFaceNormals();
                        child.geometry.computeVertexNormals();
                        //child.material.shading = THREE.SmoothShading;
                        child.name = "alfarok";
                    }
                });
                
                shadedGeometry.add(object);
                wireframeGeometry.add(clones);
                pointGeometry.add(points);
            },
        );

        let scale = 0.15;
        this.shadedGeometry.scale = new THREE.Vector3(scale, scale, scale);
        this.wireframeGeometry.scale = new THREE.Vector3(scale, scale, scale);
        this.pointGeometry.scale = new THREE.Vector3(scale, scale, scale);

        this.scene.add(shadedGeometry);
        this.scene.add(wireframeGeometry);
        this.scene.add(pointGeometry);

        // Build axis and grid
        //this.buildSceneHelpers();

        // Controls
        this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);
        // Center View
        var bb = new THREE.Box3();
        bb.setFromObject(shadedGeometry);
        bb.getCenter(this.orbitControls.target);
        this.orbitControls.update();

        // Render
        this.$emit('setRendererSize', [this.container.offsetWidth, this.container.offsetHeight]);
        this.container.appendChild(this.renderer.domElement);

        // Events
        window.addEventListener('resize', this.onWindowResize, false);
        // Ensure window size is up-to-date
        window.dispatchEvent(new Event('resize'));
    },

    // Main render function
    render: function() {
      // Monitored code goes here
      //this.stats.begin();
      this.stats.update();

      this.shadedGeometry.rotation.y += 0.01;
      this.wireframeGeometry.rotation.y += 0.01;
      this.pointGeometry.rotation.y += 0.01;

      this.renderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
      //this.stats.end();
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function() {
      // Grid
      const size = 100;
      const step = 5;
      let gridHelper = new THREE.BufferGeometry();
      let gridMaterial = new THREE.LineBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.1 });
      
      const vertices = [];

      for (var i = -size; i <= size; i += step) {
        vertices.push(-size, 0, i);
        vertices.push(size, 0, i);
        vertices.push(i, 0, -size);
        vertices.push(i, 0, size);
      }

      gridHelper.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

      let gridLines = new THREE.LineSegments(gridHelper, gridMaterial, THREE.LineSegments);
      gridLines.name = "grid";
      this.scene.add(gridLines);
      
      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function(obj) {
      while(obj.children.length > 0){ 
        this.clearObj(obj.children[0])
        obj.remove(obj.children[0]);
      }
      if(obj.geometry) obj.geometry.dispose()

      if(obj.material){ 
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach(prop => {
          if(!obj.material[prop])
            return         
          if(typeof obj.material[prop].dispose === 'function')                                  
            obj.material[prop].dispose()                                                        
        })
        obj.material.dispose()
      }
    },   

    onWindowResize: function() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize( window.innerWidth, window.innerHeight );

        // Updating an orthographic camera
        this.camera.left = 80 * window.innerWidth / window.innerHeight / - 2;
        this.camera.right = 80 * window.innerWidth / window.innerHeight / 2;
        this.camera.top = 80 / 2;
        this.camera.bottom = 80 / - 2;
        this.camera.updateProjectionMatrix();
    }
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
    this.render()
  },
  
  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener('resize', this.onWindowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  }

}

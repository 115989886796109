//
//
//
//
//
//

import Pix2PixComma10k from './Markdown/Pix2Pix-Comma10k.md';

export default {
  // Tab name
  name: 'pix2pix-comma10k',
  components: { Pix2PixComma10k }
}

//
//
//
//
//
//
//


import { Chrome } from 'vue-color'

export default {
  name: 'ColorPicker',
  components: {
    Chrome
  },
  data: function () {
    return {
        colors: {
            hex: '#194d33',
            hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
            hsv: { h: 150, s: 0.66, v: 0.30, a: 1 },
            rgba: { r: 25, g: 77, b: 51, a: 1 },
            a: 1
        }
    }
  },
  mounted() {
    this.dragColorPicker();
  },

  methods: {
    dragColorPicker: function() {
      dragElement(document.getElementById("colorPicker"));

      function dragElement(elmnt) {
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById("colorPickerHandle")) {
          /* if present, the header is where you move the DIV from:*/
          document.getElementById("colorPickerHandle").onmousedown = dragMouseDown;
        } else {
          /* otherwise, move the DIV from anywhere inside the DIV:*/
          //elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
          e = e || window.event;
          e.preventDefault();
          // get the mouse cursor position at startup:
          pos3 = e.clientX;
          pos4 = e.clientY;
          
          document.onmouseup = closeDragElement;
          // call a function whenever the cursor moves:
          document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
          e = e || window.event;
          e.preventDefault();
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          // set the element's new position:
          elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
          elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }

        function closeDragElement() {
          /* stop moving when mouse button is released:*/
          document.onmouseup = null;
          document.onmousemove = null;
        }
      }
    }
  },

  // Watch for changes on the `colors` prop
  // and emit an event to the parent when a chanege occurs
  watch: { 
    colors: {
      immediate: true, 
      handler (val, oldVal) {
        this.$emit('colorChange', val);
      }
    }
  }
}

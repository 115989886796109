//
//
//
//
//
//
//

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
  CSS3DRenderer,
  CSS3DObject,
} from "three/examples/jsm/renderers/CSS3DRenderer.js";

export default {
  // Tab name
  name: "youtube-mesh",

  // Objects stored on the component instance
  data() {
    return {
      scene: null,
      camera: null,
      orbitControls: null,
      frameId: null,
      container: null,
      cssRenderer: null,
      screen: null,
    };
  },

  // Objects passed from parent (App)
  props: ["stats"],

  methods: {
    // Entry point
    init: function () {
      this.container = document.getElementById("container");
      this.container.style.height = window.innerHeight - 50 + "px";

      // Camera
      this.camera = new THREE.PerspectiveCamera(
        45, // fov - Camera frustum vertical field of view
        this.container.offsetWidth / this.container.offsetHeight, // aspect - Camera frustum aspect ratio.
        1, // near - Camera frustum near plane.
        10000 // far - Camera frustum far plane.
      );
      this.camera.position.set(450, 815, 1000);

      this.cssRenderer = new CSS3DRenderer();
      this.cssRenderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(this.cssRenderer.domElement);

      // Scene
      this.scene = new THREE.Scene();
      this.scene.add(this.camera);

      // Build axis and grid
      this.buildSceneHelpers();

      // YouTube
      const group = new THREE.Group();
      group.add(new this.newScreen("eGUobT-ozig", 0, 0, 240, 0));
      group.add(new this.newScreen("eGUobT-ozig", 240, 0, 0, Math.PI / 2));
      group.add(new this.newScreen("eGUobT-ozig", 0, 0, -240, Math.PI));
      group.add(new this.newScreen("eGUobT-ozig", -240, 0, 0, -Math.PI / 2));
      this.scene.add(group);

      // Controls
      this.orbitControls = new OrbitControls(
        this.camera,
        this.cssRenderer.domElement
      );

      // Block iframe events when dragging camera
      const blocker = document.getElementById("blocker");
      blocker.style.display = "none";

      this.orbitControls.addEventListener("start", function () {
        blocker.style.display = "";
      });
      this.orbitControls.addEventListener("end", function () {
        blocker.style.display = "none";
      });

      // Events
      window.addEventListener("resize", this.onWindowResize, false);
      // Ensure window size is up-to-date
      window.dispatchEvent(new Event("resize"));
    },

    newScreen(id, x, y, z, ry) {
      const div = document.createElement("div");
      div.style.width = "480px";
      div.style.height = "360px";
      div.style.backgroundColor = "#000";

      const iframe = document.createElement("iframe");
      iframe.style.width = "480px";
      iframe.style.height = "360px";
      iframe.style.border = "0px";
      iframe.src = ["https://www.youtube.com/embed/", id, "?rel=0"].join("");
      div.appendChild(iframe);

      this.screen = new CSS3DObject(div);
      this.screen.position.set(x, y, z);
      this.screen.rotation.y = ry;

      return this.screen;
    },

    // Main render function
    render: function () {
      // Monitored code goes here
      //this.stats.begin();
      this.stats.update();
      this.cssRenderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
      //this.stats.end();
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function () {
      // Grid
      const size = 100;
      const step = 5;
      let gridHelper = new THREE.BufferGeometry();
      let gridMaterial = new THREE.LineBasicMaterial({
        color: 0x000000,
        transparent: true,
        opacity: 0.1,
      });

      const vertices = [];

      for (var i = -size; i <= size; i += step) {
        vertices.push(-size, 0, i);
        vertices.push(size, 0, i);
        vertices.push(i, 0, -size);
        vertices.push(i, 0, size);
      }

      gridHelper.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(vertices, 3)
      );

      let gridLines = new THREE.LineSegments(
        gridHelper,
        gridMaterial,
        THREE.LineSegments
      );
      gridLines.name = "grid";
      this.scene.add(gridLines);

      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(0.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function (obj) {
      while (obj.children.length > 0) {
        this.clearObj(obj.children[0]);
        obj.remove(obj.children[0]);
      }
      if (obj.geometry) obj.geometry.dispose();

      if (obj.material) {
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach((prop) => {
          if (!obj.material[prop]) return;
          if (typeof obj.material[prop].dispose === "function")
            obj.material[prop].dispose();
        });
        obj.material.dispose();
      }
    },

    onWindowResize: function () {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.cssRenderer.setSize(window.innerWidth, window.innerHeight);
    },
  },

  // Function called when component is initially mounted
  mounted() {
    this.init();
    this.render();
  },

  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener("resize", this.onWindowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  },
};

//
//
//
//
//
//

import BitcoinMining from './Markdown/Bitcoin-Mining.md';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'bitcoin-mining',
  components: { BitcoinMining }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    // Tab name
    name: 'authentication-sample',
  
    // Objects stored on the component instance
    data() {
        return {
            // State
            signedIn:false,

            // Sign In
            authentication: {
                username:'',
                password:'',
            },
            
            // Sign Up
            fields: {
                firstName:'',
                lastName:'',
                email:'',
                phone:'',
                desiredUsername:'',
                desiredPassword1:'',
                desiredPassword2:''
            },

            // Subscription
            subscription: {
                subscribed:true,
                package:'basic'
            },

            // Initial field validation logs
            authenticationLog:{
                username:false,
                password:false
            },
            
            registrationLog:{
                firstName:false,
                lastName:false,
                email:false,
                phone:false,
                desiredUsername:false,
                desiredPassword1:false,
                desiredPassword2:false
            },

            // Field Formatting
            expressions: {
                nameExp: /^[a-z ,.'-]+$/i,
                emailExp: /^(([^<>()[\]\.,;:\s@\']+(\.[^<>()[\]\.,;:\s@\']+)*)|(\'.+\'))@(([^<>()[\]\.,;:\s@\']+\.)+[^<>()[\]\.,;:\s@\']{2,})$/i,
                phoneExp: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                usernameExp: /^[a-zA-Z0-9]+$/,
                passExp: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
            }
        }
    },

    // Objects passed from parent (App)
    props: [],

    // Collection of component functions
    methods: {
        signinSubmit: function() { 
            // Verify the log status for each required key
            for (var key in this.authenticationLog) {
                // Check for initial errors
                if (this.authenticationLog.hasOwnProperty(key)) {
                    // If any failure
                    if(this.authenticationLog[key] === false) {
                        // Break
                        // TODO - display error message
                        alert("Please correct errors in sign in form...")
                        return;
                    }
                }
            }

            // TODO
            alert("POSTing sign in details...")

            // POST to sign in endpoint

            // If POST returns 200...
            // Clear fields and reset the log
            for (var key in this.authentication) {
                if (this.authentication.hasOwnProperty(key) && 
                    this.authenticationLog.hasOwnProperty(key)) {
                        this.authentication[key] = '';
                        this.authenticationLog[key] = false;
                }
            }
        },
        registerSubmit: function() {
            // Verify the log status for each required key
            for (var key in this.registrationLog) {
                // Check for initial errors
                if (this.registrationLog.hasOwnProperty(key)) {
                    // If any failure
                    if(this.registrationLog[key] === false) {
                        // Break
                        // TODO - display error message
                        alert("Please correct errors in registration form...")
                        return;
                    }
                }
            }

            // TODO
            alert("POSTing registration details...")

            // POST to registration endpoint

            // If POST returns 200...
            // Clear fields and reset the log
            for (var key in this.fields) {
                if (this.fields.hasOwnProperty(key) && 
                    this.registrationLog.hasOwnProperty(key)) {
                        this.fields[key] = '';
                        this.registrationLog[key] = false;
                }
            }
        },
        signinNav: function() {
            var reg = document.getElementById('register');
            var signin = document.getElementById('signin');
            if (reg.style.display === 'none') {
                signin.style.display = 'none';
                reg.style.display = 'block';

            }
            else {
                reg.style.display = 'none';
                signin.style.display = 'block';
            }
        },
        registerNav: function() { 
            var reg = document.getElementById('register');
            var signin = document.getElementById('signin');
            if (reg.style.display === 'none') {
                signin.style.display = 'none';
                reg.style.display = 'block';

            }
            else {
                reg.style.display = 'none';
                signin.style.display = 'block';
            }
        },
        keyupHandler: function(src) {
            var errorDiv = document.getElementById(src + 'Errors');

            switch(src) {

                case 'firstName': 
                    if(!this.expressions.nameExp.test(this.fields.firstName) || 
                        this.fields.firstName === null ||
                        this.fields.firstName.length < 3 ||
                        this.fields.firstName.length > 25 ) {
                            this.registrationLog.firstName = false;
                            errorDiv.innerHTML = '❌ First Name must be alphanumeric, between 3 and 25 characters';
                    }
                    else {
                        this.registrationLog.firstName = true;
                        errorDiv.innerHTML = '';
                    }
                    break;

                case 'lastName':
                    if(!this.expressions.nameExp.test(this.fields.lastName) || 
                        this.fields.lastName === null ||
                        this.fields.lastName.length < 3 ||
                        this.fields.lastName.length > 25 ) {
                            this.registrationLog.lastName = false;
                            errorDiv.innerHTML = '❌ Last Name must be alphanumeric, between 3 and 25 characters';
                    }
                    else {
                        this.registrationLog.lastName = true;
                        errorDiv.innerHTML = '';
                    }
                    break;

                case 'email':
                    // Force lower casing
                    this.fields.email = this.fields.email.toLowerCase();
                
                    if( this.fields.email === null ||
                        this.fields.email === '' ||
                        !this.expressions.emailExp.test(this.fields.email)) {
                            this.registrationLog.email = false;
                            errorDiv.innerHTML = '❌ Please provide a valid email address.';
                    }
                    else {
                        this.registrationLog.email = true;
                        errorDiv.innerHTML = '';
                    }
                    break;

                case 'phone':
                    // Remove all characters that are not numerical
                    this.fields.phone = this.fields.phone.replace(/\D/g,'');
                    
                    if(this.fields.phone === null ||
                        this.fields.phone.length !== 10 ||
                        !this.expressions.phoneExp.test(this.fields.phone)) {
                            this.registrationLog.phone = false;
                            errorDiv.innerHTML = '❌ Phone number formatting not recognized, expecting 10 digits.';
                    }
                    else {
                        this.registrationLog.phone = true;
                        errorDiv.innerHTML = '';
                    }
                    break;

                case 'desiredUsername':
                    if(this.fields.desiredUsername === null ||
                        this.fields.desiredUsername.length < 3 ||
                        this.fields.desiredUsername.length > 25 ||
                        !this.expressions.usernameExp.test(this.fields.desiredUsername)) {
                            this.registrationLog.desiredUsername = false;
                            errorDiv.innerHTML = '❌ Username is 3-25 characters, alphanumeric characters or symbols.';
                    }
                    else{
                        this.registrationLog.desiredUsername = true;
                        errorDiv.innerHTML = '';
                    }
                    break;

                case 'desiredPassword1':
                    if(!this.expressions.passExp.test(this.fields.desiredPassword1)) {
                        this.registrationLog.desiredPassword1 = false;
                        errorDiv.innerHTML = '❌ Password must be  between 6 and 16 characters, with at least 1 number, and 1 special character.';
                    }
                    else {
                        this.registrationLog.desiredPassword1 = true;
                        errorDiv.innerHTML = '';
                    }
                    break;

                case 'desiredPassword2':
                    if(this.fields.desiredPassword2 === null ||
                        this.fields.desiredPassword2 === '' ||
                        this.fields.desiredPassword1 !== this.fields.desiredPassword2) {
                        this.registrationLog.desiredPassword2 = false;
                        errorDiv.innerHTML = '❌ Verify Password must match the Password above.';
                    }
                    else {
                        this.registrationLog.desiredPassword2 = true;
                        errorDiv.innerHTML = '';
                    }
                    break;

                case 'username':
                    if(this.authentication.username === null ||
                        this.authentication.username.length < 3 ||
                        this.authentication.username.length > 25 ||
                        !this.expressions.usernameExp.test(this.authentication.username)) {
                            this.authenticationLog.username = false;
                            errorDiv.innerHTML = '❌ Username is 3-25 characters, alphanumeric characters or symbols.';
                    }
                    else{
                        this.authenticationLog.username = true;
                        errorDiv.innerHTML = '';
                    }
                    break;

                case 'password':
                    if(!this.expressions.passExp.test(this.authentication.password)) {
                        this.authenticationLog.password = false;
                        errorDiv.innerHTML = '❌ Password must be  between 6 and 16 characters, with at least 1 number, and 1 special character.';
                    }
                    else {
                        this.authenticationLog.password = true;
                        errorDiv.innerHTML = '';
                    }
                    break;

                default:
                    console.log("ERROR - keyup on unrecognized input.")
            }
        },
        handleRadioClick: function(src) {
            if(src === 'subscribe') { this.subscription.subscribed = true; }
            else if(src === 'unsubscribe') { this.subscription.subscribed = false; }
        }
    },

    // Function called when component is initially mounted
    mounted() {
        
    },
  
    // Dispose
    beforeDestroy: function () {
    
    }
}

//
//
//
//
//
//

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

class SelectableMesh extends THREE.Mesh {
  constructor() {
    super();
    this.geometry = new THREE.TorusKnotBufferGeometry(12, 3, 120, 20);
    this.material = new THREE.MeshStandardMaterial({ color: new THREE.Color('magenta').convertSRGBToLinear() });
    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.active = false;
  }

  onPointerOver(e) {
    this.material.color.set('lime');
    this.material.color.convertSRGBToLinear();
  }

  onPointerOut(e) {
    this.material.color.set('magenta');
    this.material.color.convertSRGBToLinear();
  }

  onClick(e) {
    this.active = !this.active;
    this.scale.setScalar(this.active ? 2 : 1);
  }
}

export default {
  // Tab name
  name: 'mesh-selection',

  // Objects stored on the component instance
  data() {
    return {
      mesh: null,
      scene: null,
      camera: null,
      orbitControls: null,
      frameId: null,
      container: null,
      raycaster: new THREE.Raycaster(),
      mouse: new THREE.Vector2(),
      intersects: [],
      hovered: {}
    }
  },

  // Objects passed from parent (App)
  props: ['renderer', 'stats'],

  methods: {
    // Entry point
    init: function () {
      this.container = document.getElementById('viewer');
      this.container.style.height = window.innerHeight - 50 + "px";

      // Camera
      this.camera = new THREE.PerspectiveCamera(
        45, // fov - Camera frustum vertical field of view
        this.container.offsetWidth / this.container.offsetHeight, // aspect - Camera frustum aspect ratio.
        1, // near - Camera frustum near plane.
        10000 // far - Camera frustum far plane.
      )
      this.camera.position.set(0, 20, 100);

      // Orthographic Camera
      /*
      this.camera = new THREE.OrthographicCamera( 
        80 * window.innerWidth / window.innerHeight / - 2, // left - Camera frustum left plane
        80 * window.innerWidth / window.innerHeight / 2,  // right - Camera frustum right plane
        80 / 2, // top - Camera frustum top plane
        80 / - 2,  // bottom - Camera frustum bottom plane
        1, // near - Camera frustum near plane
        2000 // far - Camera frustum far plane
      )
      */

      // Light
      const ambientLight = new THREE.AmbientLight();
      const pointLight = new THREE.PointLight();
      pointLight.position.set(10, 10, 10);

      // Mesh
      this.mesh = new SelectableMesh();

      // Scene
      this.scene = new THREE.Scene();
      this.scene.add(this.camera, this.mesh, ambientLight, pointLight);

      // Build axis and grid
      //this.buildSceneHelpers();

      // Controls
      this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

      // Render
      this.$emit('setRendererSize', [this.container.offsetWidth, this.container.offsetHeight]);
      this.container.appendChild(this.renderer.domElement);

      // Events
      window.addEventListener('resize', this.onWindowResize, false);
      // Ensure window size is up-to-date
      window.dispatchEvent(new Event('resize'));

      window.addEventListener('pointermove', (e) => {
        this.mouse.set((e.clientX / this.container.offsetWidth) * 2 - 1, -(e.clientY / this.container.offsetHeight) * 2 + 1)
        this.raycaster.setFromCamera(this.mouse, this.camera)
        this.intersects = this.raycaster.intersectObjects(this.scene.children, true);

        // If a previously hovered item is not among the hits we must call onPointerOut
        Object.keys(this.hovered).forEach((key) => {
          const hit = this.intersects.find((hit) => hit.object.uuid === key)
          if (hit === undefined) {
            const hoveredItem = this.hovered[key]
            if (hoveredItem.object.onPointerOver) hoveredItem.object.onPointerOut(hoveredItem)
            delete this.hovered[key]
          }
        })

        this.intersects.forEach((hit) => {
          // If a hit has not been flagged as hovered we must call onPointerOver
          if (!this.hovered[hit.object.uuid]) {
            this.hovered[hit.object.uuid] = hit
            if (hit.object.onPointerOver) hit.object.onPointerOver(hit)
          }
          // Call onPointerMove
          if (hit.object.onPointerMove) hit.object.onPointerMove(hit)
        })
      })

      window.addEventListener('click', (e) => {
        this.intersects = this.raycaster.intersectObjects(this.scene.children, true)
        this.intersects.forEach((hit) => {
          // Call onClick
          if (hit.object.onClick) hit.object.onClick(hit)
        })
      })
    },

    // Main render function
    render: function () {
      // Monitored code goes here
      //this.stats.begin();
      this.stats.update();
      this.renderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
      //this.stats.end();
    },

    onPointerOver(e) {
      this.material.color.set('lime');
      this.material.color.convertSRGBToLinear();
    },

    onPointerOut(e) {
      this.material.color.set('magenta');
      this.material.color.convertSRGBToLinear();
    },

    onClick(e) {
      this.active = !this.active;
      this.scale.setScalar((this.active ? 1.5 : 1))
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function () {
      // Grid
      const size = 100;
      const step = 5;
      let gridHelper = new THREE.BufferGeometry();
      let gridMaterial = new THREE.LineBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.1 });

      const vertices = [];

      for (var i = -size; i <= size; i += step) {
        vertices.push(-size, 0, i);
        vertices.push(size, 0, i);
        vertices.push(i, 0, -size);
        vertices.push(i, 0, size);
      }

      gridHelper.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

      let gridLines = new THREE.LineSegments(gridHelper, gridMaterial, THREE.LineSegments);
      gridLines.name = "grid";
      this.scene.add(gridLines);

      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function (obj) {
      while (obj.children.length > 0) {
        this.clearObj(obj.children[0])
        obj.remove(obj.children[0]);
      }
      if (obj.geometry) obj.geometry.dispose()

      if (obj.material) {
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach(prop => {
          if (!obj.material[prop])
            return
          if (typeof obj.material[prop].dispose === 'function')
            obj.material[prop].dispose()
        })
        obj.material.dispose()
      }
    },

    onWindowResize: function () {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);

      // Updating an orthographic camera
      /*
      this.camera.left = 80 * window.innerWidth / window.innerHeight / - 2;
      this.camera.right = 80 * window.innerWidth / window.innerHeight / 2;
      this.camera.top = 80 / 2;
      this.camera.bottom = 80 / - 2;
      this.camera.updateProjectionMatrix();
      */
    }
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
    this.render()
  },

  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener('resize', this.onW>indowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  }

}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EthCrypto from "eth-crypto";
import SignAndVerifyEth from "./Markdown/Sign-and-Verify-ETH.md";
import hljs from "highlight.js"
import hljsDefineSolidity from "highlightjs-solidity";
import "highlight.js/styles/github-gist.css";

export default {
  // Tab name
  name: "sign-and-verify-eth",
  components: { SignAndVerifyEth },
  data() {
    return {
      signerIdentity: null,
      publicKey: "",
      keccak256Input: "Enter a msg to hash...",
      hashed: "",
      signature: "",
    }
  },
  methods: {
    init() {
      hljsDefineSolidity(hljs);
      hljs.initHighlightingOnLoad();

      this.signerIdentity = EthCrypto.createIdentity();
      this.publicKey = this.signerIdentity.address;
    },
    hash() {
      this.hashed = EthCrypto.hash.keccak256(this.keccak256Input);
    },
    sign() {
      this.signature = EthCrypto.sign(this.signerIdentity.privateKey, this.hashed)
    }
  },
  mounted() {
    this.init()
  },
}

//
//
//
//
//
//

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

export default {
  // Tab name
  name: 'webgl-template',
  
  // Objects stored on the component instance
  data() {
    return {
      cube: null,
      scene: null,
      camera: null,
      orbitControls: null,
      frameId: null,
      container: null
    }
  },

  // Objects passed from parent (App)
  props: ['renderer', 'stats'],

  methods: {
    // Entry point
    init: function() {  
      this.container = document.getElementById( 'viewer' );
      this.container.style.height = window.innerHeight - 50 + "px";
          
      // Camera
      this.camera = new THREE.PerspectiveCamera(
        45, // fov - Camera frustum vertical field of view
        this.container.offsetWidth / this.container.offsetHeight, // aspect - Camera frustum aspect ratio.
        1, // near - Camera frustum near plane.
        10000 // far - Camera frustum far plane.
      )
      this.camera.position.set( 0, 20, 100 );

      // Orthographic Camera
      /*
      this.camera = new THREE.OrthographicCamera( 
        80 * window.innerWidth / window.innerHeight / - 2, // left - Camera frustum left plane
        80 * window.innerWidth / window.innerHeight / 2,  // right - Camera frustum right plane
        80 / 2, // top - Camera frustum top plane
        80 / - 2,  // bottom - Camera frustum bottom plane
        1, // near - Camera frustum near plane
        2000 // far - Camera frustum far plane
      )
      */

      // Geometry
      const geometry = new THREE.BoxGeometry(10, 10, 10);
      const material = new THREE.MeshBasicMaterial({ color: 0xff00ff });
      this.cube = new THREE.Mesh(geometry, material);
    
      // Scene
      this.scene = new THREE.Scene();
      this.scene.add(this.camera);
      this.scene.add(this.cube);

      // Build axis and grid
      this.buildSceneHelpers();
      
      // Controls
      this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

      // Render
      this.$emit('setRendererSize', [this.container.offsetWidth, this.container.offsetHeight]);
      this.container.appendChild(this.renderer.domElement);
      
      // Events
      window.addEventListener('resize', this.onWindowResize, false);
      // Ensure window size is up-to-date
      window.dispatchEvent(new Event('resize'));
    },

    // Main render function
    render: function() {
      // Monitored code goes here
      //this.stats.begin();
      this.cube.rotation.x += 0.01;
      this.cube.rotation.y += 0.01;
      this.stats.update();
      this.renderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
      //this.stats.end();
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function() {
      // Grid
      const size = 100;
      const step = 5;
      let gridHelper = new THREE.BufferGeometry();
      let gridMaterial = new THREE.LineBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.1 });
      
      const vertices = [];

      for (var i = -size; i <= size; i += step) {
        vertices.push(-size, 0, i);
        vertices.push(size, 0, i);
        vertices.push(i, 0, -size);
        vertices.push(i, 0, size);
      }

      gridHelper.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

      let gridLines = new THREE.LineSegments(gridHelper, gridMaterial, THREE.LineSegments);
      gridLines.name = "grid";
      this.scene.add(gridLines);
      
      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function(obj) {
      while(obj.children.length > 0){ 
        this.clearObj(obj.children[0])
        obj.remove(obj.children[0]);
      }
      if(obj.geometry) obj.geometry.dispose()

      if(obj.material){ 
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach(prop => {
          if(!obj.material[prop])
            return         
          if(typeof obj.material[prop].dispose === 'function')                                  
            obj.material[prop].dispose()                                                        
        })
        obj.material.dispose()
      }
    },   

    onWindowResize: function() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize( window.innerWidth, window.innerHeight );

        // Updating an orthographic camera
        /*
        this.camera.left = 80 * window.innerWidth / window.innerHeight / - 2;
        this.camera.right = 80 * window.innerWidth / window.innerHeight / 2;
        this.camera.top = 80 / 2;
        this.camera.bottom = 80 / - 2;
        this.camera.updateProjectionMatrix();
        */
    }

    /*
    onMouseMove: function(event) {
        event.preventDefault();
        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
    }
    */
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
    this.render()
  },
  
  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener('resize', this.onWindowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  }

}

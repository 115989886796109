//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

export default {
  // Tab name
  name: 'webgl-slider-ui',

  // UI tool
  components: {
    VueSlider
  },
  
  // Objects stored on the component instance
  data() {
    return {
      points: null,
      scene: null,
      camera: null,
      orbitControls: null,
      frameId: null,
      value: 0.05,
      container: null
    }
  },

  // Objects passed from parent (App)
  props: ['renderer', 'stats'],

  methods: {
    // Entry point
    init: function() {     
      this.container = document.getElementById( 'viewer' );
      this.container.style.height = window.innerHeight - 120 + "px"

      // Camera
      this.camera = new THREE.PerspectiveCamera(
        45, // fov - Camera frustum vertical field of view
        this.container.offsetWidth / this.container.offsetHeight, // aspect - Camera frustum aspect ratio.
        1, // near - Camera frustum near plane.
        10000 // far - Camera frustum far plane.
      )
      this.camera.position.set( 0, 20, 100 );

       // Scene
      this.scene = new THREE.Scene();
      this.scene.add(this.camera);

      // Geometry
      this.buildGeometry();
    
      // Render
      this.$emit('setRendererSize', [this.container.offsetWidth, this.container.offsetHeight]);

      // Build axis and grid
      //this.buildSceneHelpers();
      
      // Controls
      this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);
      
      // Append renderer to dom
      this.container.appendChild(this.renderer.domElement);

      // Events
      window.addEventListener('resize', this.onWindowResize, false);
      // Ensure window size is up-to-date
      window.dispatchEvent(new Event('resize'));
    },

    buildGeometry: function() {
      var particles = 500;
      var geometry = new THREE.BufferGeometry();
      var positions = [];
      var colors = [];
      var color = new THREE.Color();
      var n = 40, n2 = n / 2; // particles inside cube
      
      for ( var i = 0; i < particles; i ++ ) {
        // Positions
        var x = Math.random() * n - n2;
        var y = Math.random() * n - n2;
        var z = Math.random() * n - n2;
        positions.push( x, y, z );
        // Colors
        var vx = ( x / n ) + 0.5;
        var vy = ( y / n ) + 0.5;
        var vz = ( z / n ) + 0.5;
        color.setRGB( vx, vy, vz );
        colors.push( color.r, color.g, color.b );
      }

      geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( positions, 3 ) );
      geometry.setAttribute( 'color', new THREE.Float32BufferAttribute( colors, 3 ) );
      geometry.computeBoundingSphere();
      
      var material = new THREE.PointsMaterial( { size: 10, vertexColors: THREE.VertexColors } );
      this.points = new THREE.Points( geometry, material );
      this.scene.add( this.points );
    },

    // Main render function
    render: function() {
      //this.points.position.x += 0.05;
			this.points.rotation.y += this.value;

      this.renderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
      
      this.stats.update();
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function() {
      // Grid
      const size = 100;
      const step = 5;
      let gridHelper = new THREE.BufferGeometry();
      let gridMaterial = new THREE.LineBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.1 });
      
      const vertices = [];

      for (var i = -size; i <= size; i += step) {
        vertices.push(-size, 0, i);
        vertices.push(size, 0, i);
        vertices.push(i, 0, -size);
        vertices.push(i, 0, size);
      }

      gridHelper.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

      let gridLines = new THREE.LineSegments(gridHelper, gridMaterial, THREE.LineSegments);
      gridLines.name = "grid";
      this.scene.add(gridLines);
      
      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function(obj) {
      while(obj.children.length > 0){ 
        this.clearObj(obj.children[0])
        obj.remove(obj.children[0]);
      }
      if(obj.geometry) obj.geometry.dispose()

      if(obj.material){ 
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach(prop => {
          if(!obj.material[prop])
            return         
          if(typeof obj.material[prop].dispose === 'function')                                  
            obj.material[prop].dispose()                                                        
        })
        obj.material.dispose()
      }
    },

    onWindowResize: function() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize( window.innerWidth, window.innerHeight );
    }

    /*
    onMouseMove: function(event) {
        event.preventDefault();
        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
    }
    */
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
    this.render()
  },
  
  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener('resize', this.onWindowResize, false);
    
    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  }

}

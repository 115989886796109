//
//
//
//
//
//

import SelfDrivingNotes from './Markdown/Self-Driving-Cars-Notes.md';
import hljs from 'highlight.js/lib/highlight';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'self-driving-notes',
  components: { SelfDrivingNotes }
}

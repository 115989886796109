//
//
//
//
//
//

import LinuxFundamentals from './Markdown/Linux-Fundamentals.md';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'linux-fundamentals',
  components: { LinuxFundamentals }
}

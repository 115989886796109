//
//
//
//
//
//

import InstallingSubstrateMacOS from './Markdown/Installing-Substrate-MacOS.md';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'installing-substrate-mac-os',
  components: { InstallingSubstrateMacOS }
}

//
//
//
//
//
//

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

export default {
  // Tab name
  name: 'sprite-labels',

  // Objects stored on the component instance
  data() {
    return {
      scene: null,
      camera: null,
      orbitControls: null,
      frameId: null,
      container: null
    }
  },

  // Objects passed from parent (App)
  props: ['renderer', 'stats'],

  methods: {
    // Entry point
    init: function () {
      this.container = document.getElementById('viewer');
      this.container.style.height = window.innerHeight - 50 + "px";

      // Camera
      this.camera = new THREE.OrthographicCamera(
        80 * window.innerWidth / window.innerHeight / - 2, // left - Camera frustum left plane
        80 * window.innerWidth / window.innerHeight / 2,  // right - Camera frustum right plane
        80 / 2, // top - Camera frustum top plane
        80 / - 2,  // bottom - Camera frustum bottom plane
        1, // near - Camera frustum near plane
        2000 // far - Camera frustum far plane
      )
      this.camera.position.set(0, 0, 100);

      // Geometry
      const geometry = new THREE.BoxGeometry(15, 15, 15);
      const material = new THREE.MeshBasicMaterial({ color: 0xff00ff });
      const cube = new THREE.Mesh(geometry, material);

      // Scene
      this.scene = new THREE.Scene();
      this.scene.add(this.camera, cube);

      // Sprite
      // Create a canvas element to draw the text on
      var canvas = document.createElement("canvas");
      canvas.width = 1150;
      canvas.height = 450;

      var context = canvas.getContext("2d");
      context.font = "Bold 200px Arial";
      //context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillStyle = "rgba(0, 0, 0, 1)";
      context.fillText("Sprite Label", 0, 250);

      // Create a texture from the canvas
      const texture = new THREE.Texture(canvas);
      texture.needsUpdate = true;

      // Create a material that uses the texture
      const spriteMaterial = new THREE.SpriteMaterial({ map: texture, transparent: true });

      // Create a Sprite object with the material
      const sprite1 = new THREE.Sprite(spriteMaterial);

      // Set the size of the sprite to match the canvas
      sprite1.scale.set(canvas.width / 100, canvas.height / 100, 1);

      // Set the position of the sprite in 3D space
      sprite1.position.set(-10, -10, 0);

      const sprite2 = sprite1.clone();
      const sprite3 = sprite1.clone();
      const sprite4 = sprite1.clone();

      sprite2.position.set(-10, 10, 0);
      sprite3.position.set(10, 10, 0);
      sprite4.position.set(10, -10, 0);

      // Add the sprite to the scene
      this.scene.add(sprite1, sprite2, sprite3, sprite4);

      // Build axis and grid
      this.buildSceneHelpers();

      // Controls
      this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

      // Render
      this.$emit('setRendererSize', [this.container.offsetWidth, this.container.offsetHeight]);
      this.container.appendChild(this.renderer.domElement);

      // Events
      window.addEventListener('resize', this.onWindowResize, false);
      // Ensure window size is up-to-date
      window.dispatchEvent(new Event('resize'));
    },

    // Main render function
    render: function () {
      // Monitored code goes here
      //this.stats.begin();
      this.stats.update();
      this.renderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
      //this.stats.end();
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function () {
      // Axes
      let axesHelper = new THREE.AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function (obj) {
      while (obj.children.length > 0) {
        this.clearObj(obj.children[0])
        obj.remove(obj.children[0]);
      }
      if (obj.geometry) obj.geometry.dispose()

      if (obj.material) {
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach(prop => {
          if (!obj.material[prop])
            return
          if (typeof obj.material[prop].dispose === 'function')
            obj.material[prop].dispose()
        })
        obj.material.dispose()
      }
    },

    onWindowResize: function () {
      this.camera.left = 80 * window.innerWidth / window.innerHeight / - 2;
      this.camera.right = 80 * window.innerWidth / window.innerHeight / 2;
      this.camera.top = 80 / 2;
      this.camera.bottom = 80 / - 2;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    }
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
    this.render()
  },

  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener('resize', this.onWindowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    //console.log("Attempting to delete [" + this.scene.children.length + "] objects from the scene.")
    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  }

}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Chartist from 'chartist'
import J1950 from './../../assets/F1RaceStats/1950.json'

export default {
  // Tab name
  name: 'formula-1-race-stats',
  
  // Objects stored on the component instance
  data() {
    return {
        container: null,
        stats: null
    }
  },

  // Objects passed from parent (App)
  props: [],

  methods: {
    // Entry point
    init() {
      this.stats = J1950;

      var results = this.stats["1950"].results;

      var raceNames = [];
      var raceNumbers = [];

      for(let i=0; i<results.length; i++) {
        raceNames.push(results[i].raceName);
        raceNumbers.push(results[i].round);
      }

      /*
      this.container = document.getElementById( 'viewer' );
      new Chartist.Line('.ct-chart', {
        labels: raceNames,
        series: [raceNumbers]
      }, 
      // options
      {
        fullWidth: true,
        height: '400px',
        chartPadding: {
          top: 50,
          left: 400,
          right: 400
        },
        axisY: {
          onlyInteger: true
        }
      });
      */
    }
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
  },
  
  // Dispose
  beforeDestroy: function () {

  }

}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Chartist from 'chartist'

export default {
  // Tab name
  name: 'activation-functions',
  
  // Objects stored on the component instance
  data() {
    return {
      container: null 
    }
  },

  // Objects passed from parent (App)
  props: [],

  methods: {
    // Entry point
    init() {  
      this.container = document.getElementById( 'viewer' );

      // Binary Step
      var bStep = this.binaryStep(-5, 5);
      this.draw('#chart1', bStep, Chartist.Interpolation.step());

      // Linear
      var linear = this.linear(-5, 5);
      this.draw('#chart2', linear, null);

      // Sigmoid
      var sigmoid = this.sigmoid(-5, 5);
      this.draw('#chart3', sigmoid, Chartist.Interpolation.cardinal());

      // tanH
      var tanH = this.tanH(-5, 5);
      this.draw('#chart4', tanH, Chartist.Interpolation.cardinal());

      // ReLU
      var relu = this.relu(-5, 5);
      this.draw('#chart5', relu, null);

      // leaky ReLU
      var leakyReLU = this.leakyReLU(-5, 5);
      this.draw('#chart6', leakyReLU, null);

      // exponential linear unit
      var elu = this.exponentialLinearUnit(-5, 5);
      this.draw('#chart7', elu, Chartist.Interpolation.monotoneCubic())

      // swish
      var swish = this.swish(-5, 5);
      this.draw('#chart8', swish, Chartist.Interpolation.cardinal())

      // softmax
      var softmax = this.softmax(-5, 5);
      this.draw('#chart9', softmax, Chartist.Interpolation.cardinal())
    },

    // Plot a new graph
    draw(name, values, smoothing) {
      new Chartist.Line(name, {
      labels: values[0],
      series: [
          values[1]
      ]
      }, 
      // Options
      {
          fullWidth: true,
          height: '250px',
          width: '500px',
          chartPadding: {
              right: 40
          },
          lineSmooth: smoothing,
          showPoint: false
      });
    },

    // Binary Step
    binaryStep(start, stop) {
      var x = [];
      var y = [];

      for(var i = start; i <= stop; i++) {
          if(i < 0) {
              x.push(i);
              y.push(0);
          }
          else {
              x.push(i);
              y.push(1);
          }
      }

      return([x, y]);
    },

    // Linear
    linear(start, stop) {
      var x = [];
      var y = [];

      for(var i = start; i <= stop; i++) {
        x.push(i);
        y.push(i * 0.1);
      }

      return([x, y]);
    },

    // Sigmoid
    sigmoid(start, stop) {
      var x = [];
      var y = [];

      for(var i = start; i <= stop; i++) {
        x.push(i);
        y.push(1/(1+Math.pow(Math.E, -i)));
      }

      return([x, y]);
    },

    // tanH
    tanH(start, stop) {
      var x = [];
      var y = [];

      for(var i = start; i <= stop; i++) {
        x.push(i);
        var sigY = (1/(1+Math.pow(Math.E, (-i*2))));
        y.push(sigY * 2 - 1);
      }

      return([x, y]);
    },

    // ReLU
    relu(start, stop) {
      var x = [];
      var y = [];

      for(var i = start; i <= stop; i++) {
        x.push(i);
        if(i < 0) {
          y.push(0);
        }
        else {
          y.push(i)
        }
      }

      return([x, y]);
    },

    // Leaky ReLU
    leakyReLU(start, stop) {
      var x = [];
      var y = [];

      for(var i = start; i <= stop; i++) {
        x.push(i);
        if(i < 0) {
          y.push(0.01*i);
        }
        else {
          y.push(i);
        }
      }

      return([x, y]);
    },

    // Exponential Linear Unit (ELU)
    exponentialLinearUnit(start, stop) {
      var x = [];
      var y = [];
      var alpha = 2;

      for(var i = start; i <= stop; i++) {
        x.push(i);
        if(i >= 0) {
          y.push(i);
        }
        else {
          var eluY = alpha * (Math.pow(Math.E, i) - 1);
          y.push(eluY);
        }
      }
      
      return([x, y]); 
    },

    // Swish
    swish(start, stop) {
      var x = [];
      var y = [];

      for(var i = start; i <= stop; i++) {
        x.push(i);
        // x*sigmoid(x)
        y.push(i * (1/(1+Math.pow(Math.E, -i))));
      }

      return([x, y]);
    },

    // Softmax
    softmax(start, stop) {
      var x = [];
      var y = [];

      for(var i = start; i <= stop; i++) {
        x.push(i);
      }

      // Javascript softmax implementation
      y = x.map(function(value,index) { 
        return Math.exp(value) / x.map( function(y) { 
          return Math.exp(y) 
        }).reduce( function(a,b) {
          return a+b 
        })
      })

      return([x, y]);
    }
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
  },
}

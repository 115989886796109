//
//
//
//
//
//

import WebAppDeploy from "./Markdown/Automated-Web-App-Deployment.md";
import hljs from "highlight.js/lib/highlight";
import "highlight.js/styles/github-gist.css";

export default {
  // Tab name
  name: "automated-web-app-deployment",
  components: { WebAppDeploy }
}

//
//
//
//
//
//


import MainViewer from '@/components/MainViewer'

export default {
  name: 'App',
  data: function () {
    return {
    }
  },
  components: {
    MainViewer
  }
}

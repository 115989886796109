//
//
//
//
//
//
//
//


import Chartist from 'chartist'

export default {
  // Tab name
  name: 'line-plot',
  
  // Objects stored on the component instance
  data() {
    return {
        container: null
    }
  },

  // Objects passed from parent (App)
  props: [],

  methods: {
    // Entry point
    init() {  
      this.container = document.getElementById( 'viewer' );
      new Chartist.Line('.ct-chart', {
        labels: ['A', 'B', 'C', 'D', 'E'],
        series: [
          [1, 1, 1, 1, 1],
          [2, 0, 2, 0, 2],
          [0, 2, 0, 2, 0]
        ]
      }, 
      // options
      {
        fullWidth: true,
        height: '400px',
        width: '400px',
        chartPadding: {
          right: 40
        }
      });
    }
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
  },
  
  // Dispose
  beforeDestroy: function () {

  }

}

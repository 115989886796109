//
//
//
//
//
//

import SelfDrivingVocab from './Markdown/Self-Driving-Cars-Vocab.md';
import hljs from 'highlight.js/lib/highlight';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'self-driving-vocab',
  components: { SelfDrivingVocab }
}

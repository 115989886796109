//
//
//
//
//
//

import ContractSecurity from './Markdown/Contract-Security-Intro.md';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'contract-security',
  components: { ContractSecurity }
}

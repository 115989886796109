//
//
//
//
//
//

import CPUvsGPU from './Markdown/CPU-vs-GPU.md';
import hljs from 'highlight.js/lib/highlight';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'cpu-vs-gpu',
  components: { CPUvsGPU }
}

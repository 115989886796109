//
//
//
//
//
//

import ForgeAuthentication from './Markdown/ForgeAuthentication.md';

export default {
  // Tab name
  name: 'forge-authentication',
  components: { ForgeAuthentication }
}

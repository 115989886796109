//
//
//
//
//
//

import TensorFlowGPU from './Markdown/TensorFlow-2.x-GPU-Setup.md';
import hljs from 'highlight.js/lib/highlight';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'tensorflow-2.x-gpu-setup',
  components: { TensorFlowGPU }
}

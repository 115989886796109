//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  // Tab name
  name: 'contact',
  
  // Objects stored on the component instance
  data() {
    return {
      container: null
    }
  },

  // Objects passed from parent (App)
  props: ['renderer', 'stats'],

  methods: {
    // Entry point
    init: function() {  

    }
  },

  // Function called when component is initially mounted
  mounted() {
    this.init()
  }
}
